<template>
  <div class="header-box">
    <div class="header-left"
         v-if="hasUserInfo">
      <div class="logo clearfix">
        <div class="fl">
          <img v-if="!isPartner"
               :src="logoSrc"
               alt="">
          <img v-else-if="$store.state.user.loginInfo.partner_logo_url"
               :src="$store.state.user.loginInfo.partner_logo_url" />
        </div>
        <div class="fl"
             v-if="!isPartner">
          <div class="logoTitle">{{$t('topHead.title')}}</div>
          <div class="logoText">MOOJING.COM</div>
        </div>
        <div class="fl"
             v-else>
          <div style="height: 100%; line-height: 50px;font-size: 16px; font-weight: 700">{{$store.state.user.loginInfo.partner_title}}</div>
        </div>
        <div class="fl"
             v-if="!isPartner">
          <el-popover placement="bottom-start"
                      trigger="hover">
            <div>
              <img style="width: 200px"
                   src="../assets/kf.jpeg"
                   alt="">
            </div>
            <div style="text-align:center;display: inline-block;width: 190px"
                 :class="{'en-font': $i18n.locale == 'en'}">{{$t('topHeader.contact')}}</div>
            <i class="iconfont icon-kefu kf"
               slot="reference"><span class="text"
                    :class="{'en-font': $i18n.locale == 'en'}">{{$t('topHeader.support')}}</span></i>
          </el-popover>
        </div>
        <div class="fl"
             v-if="!isPartner"
             style="line-height: 56px;margin-left: 20px;display:flex;justify-cotent:center">
          <i class="iconfont icon-wendang"
             style="font-size:20px;">
          </i>
          <a :href="doc_url"
             style="font-size: 14px;margin-left: 3px"
             class="header-doc-link"
             target="_blank">{{$t('topHeader.doc')}}</a>
        </div>
        <!-- <div class="fl"
             v-if="!isPartner">
          <el-popover placement="bottom-start"
                      trigger="hover">
            <div>
              <img :src="$store.state.user.isOversea ? require('@/assets/PO_QR_oversea.png') : require('@/assets/PO_QR.png')"
                   alt=""
                   style="width: 190px">
            </div>
            <div style="text-align:center;width: 190px;word-break: break-word;"
                 :class="{'en-font': $i18n.locale == 'en'}">{{$t('topHead.po_describe')}}</div>
            <i class="iconfont icon-kefu2 kf"
               slot="reference"><span class="text"
                    :class="{'en-font': $i18n.locale == 'en'}">{{$t('topHead.po_request')}}</span></i>
          </el-popover>
        </div> -->
      </div>
    </div>
    <div class="header-right">
      <el-dropdown placement="bottom-start"
                   style="margin-right: 20px"
                   @command="changeTheme">
        <span class="el-dropdown-link">{{$t('topHeader.theme')}}<i class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="orange">{{$t('topHeader.orange')}}</el-dropdown-item>
          <el-dropdown-item command="default">{{$t('topHeader.blue')}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown v-if="isOversea"
                   placement="bottom-start"
                   @command="changeLanguageMode">
        <span class="el-dropdown-link">
          {{languageMode == 'system' ?
          (lang=='zh' ? $t('topHead.data_language_chinese') : $t('topHead.data_language_english')) :
          languageMode == 'source' ?
          (lang=='zh' ? $t('topHead.source_language_chinese') : $t('topHead.source_language_english')) :
          (lang=='zh' ? $t('topHead.source_language_chinese') + '+' + $t('topHead.Chinese') :
          $t('topHead.source_language_english') + '+' + $t('topHead.English'))}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="system">{{lang=='zh' ? $t('topHead.data_language_chinese') :$t('topHead.data_language_english')}}</el-dropdown-item>
          <el-dropdown-item command="source">{{lang=='zh' ? $t('topHead.source_language_chinese') : $t('topHead.source_language_english')}}</el-dropdown-item>
          <el-dropdown-item command="both">
            {{lang=='zh' ? $t('topHead.source_language_chinese') : $t('topHead.source_language_english')}} +
            {{lang=='zh' ? $t('topHead.Chinese') :$t('topHead.English')}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown v-if="isFenxiEn"
                   placement="bottom-start"
                   @command="changeFenxiLanguageMode">
        <span class="el-dropdown-link">
          <span v-show="fenxiLanguageMode == 'zh'">Data Language: Chinese</span>
          <span v-show="fenxiLanguageMode == 'en'">Data Language: English</span>
          <span v-show="fenxiLanguageMode == 'both'">Data Language: Chinese + English</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh">Data Language: Chinese</el-dropdown-item>
          <el-dropdown-item command="en">Data Language: English</el-dropdown-item>
          <el-dropdown-item command="both">
            Data Language: Chinese + English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <!-- dropdown  汇率-->
      <el-dropdown style="margin-left:20px;"
                   v-if="isOversea"
                   placement="bottom-start"
                   @command="changeExchangeRate"
                   @visible-change="exchangeRateVisibleChange">
        <span class="el-dropdown-link">
          {{exchangeRateList[0]&&exchangeRateList[0].currency_text}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="(item,index) in exchangeRateList"
                            :key="index"
                            :command="item">{{item.currency_text}}</el-dropdown-item>

          <el-dropdown-item command="custom"
                            v-if="exchangeRateCustomTransRes.status == 'show'">
            <div class="exchange-rate-custom-list">
              <div class="exchange-rate-custom-list-left">
                {{$t('topHead.custom_settings')}}
                <el-input size="mini"
                          @click.stop.native=""
                          style="width:80px;margin-right:4px"
                          v-model="exchangeRateCustomTransRes.custom_rate"
                          :placeholder="$t('topHead.custom_exchange_rate')">
                </el-input>
                <span>{{exchangeRateCustomData.currency_symbol}}{{lang=='zh' ? exchangeRateCustomData.currency_zh :
                  exchangeRateCustomData.currency_en}})</span>
                <el-tooltip class="item"
                            effect="dark"
                            :content="$t('topHead.confirmation_of_change')"
                            placement="bottom">
                  <el-button type="primary"
                             size="mini"
                             icon="el-icon-check"
                             circle
                             @click.stop="exchangeRateCustomEdit()"></el-button>
                </el-tooltip>
              </div>
              <div class="exchange-rate-custom-list-right"
                   @click.stop="setExchangeRateVal(exchangeRateCustomData.rate)">
                {{$t('topHead.latest_exchange_rate')}}{{exchangeRateCustomData.rate}}
              </div>

            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <!-- 分析+ 选择语言 oversea也需要分析+的语言选择功能-->
      <el-dropdown style="margin-left:20px"
                   placement="bottom-start"
                   @command="changeFenxiLanguage">
        <span class="el-dropdown-link">
          {{languageText}}<i class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zh">系统语言：中文</el-dropdown-item>
          <el-dropdown-item command="en">System language: English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <div class="complete"
           v-if="!isOversea&&isCompleted&&!isPartner">
        <el-tooltip effect="dark"
                    content="魔镜CMI布道师"
                    placement="top">
          <img src="../assets/medal.png"
               class="completeImg cp"
               :class="{'flicker':isCompletedMove}"
               @click="openHeadProduct">
        </el-tooltip>
      </div> -->
      <userinfo @userLogin="userLoginValue"
                @on-changeLanguage="changeLanguage"> </userinfo>

      <!-- <div class="language">
        <span @click="changeLanguge('zh')"
              :class="{'selected': localS.getItem('language')=='zh'}">{{$t('topHead.Chinese')}}</span>
        <el-divider direction="vertical"></el-divider>
        <span @click="changeLanguge('en')"
              :class="{'selected': localS.getItem('language')=='en'}">{{$t('topHead.English')}}</span>
      </div> -->
    </div>

  </div>
</template>

<script>
import { userinfo } from 'common'
import {
  getCustomCatData,
  exchangeRate,
  exchangeRateCustom,
} from '@/api/public'
import { setTheme } from '@/style/theme-model'
const localS = localStorage
export default {
  components: {
    userinfo,
  },
  data() {
    return {
      // isCompleted: false,
      // isCompletedMove: false,
      exchangeRateList: [],
      localS,
      loginInfo: {},
      lang: localS.getItem('language'),
      isOversea: this.$store.state.user.isOversea,
      doc_url: this.$store.state.user.isOversea
        ? 'https://qmctsz2s8t.feishu.cn/drive/folder/fldcnvHlKq2yFP5LbM6cy7eXFIg'
        : 'https://qmctsz2s8t.feishu.cn/drive/folder/fldcnMToVrIlLZg4AeRgyC9VAb6',
      fenxiLanguage: localStorage.getItem('fenxiLanguage')
        ? localStorage.getItem('fenxiLanguage')
        : 'zh',
      logoSrc:
        this.$store.state.user.systemTheme == 'orange'
          ? require('../assets/logo_ld_orange.png')
          : require('../assets/logo_ld.png'),
      exchangeRateCustomData: {},
      exchangeRateCustomTransRes: {
        status: 'show',
        custom_rate: '',
      },
    }
  },
  computed: {
    languageMode: {
      get() {
        return this.$store.state.user.languageMode
      },
      set(val) {
        this.$store.dispatch('user/changeLanguageMode', val)
      },
    },
    fenxiLanguageMode: {
      get() {
        return this.$store.state.user.fenxiLanguageMode
      },
      set(val) {
        this.$store.dispatch('user/changeFenxiLanguageMode', val)
      },
    },
    isFenxiEn() {
      return !this.isOversea && this.localS.language == 'en'
    },
    isPartner() {
      return this.$store.state.user.loginInfo.is_partner
    },
    hasUserInfo() {
      return Object.keys(this.$store.state.user.loginInfo).length > 0
    },
    languageText() {
      if (this.fenxiLanguage == 'en') {
        return 'System language: English'
      } else {
        return '系统语言：中文'
      }
    },
  },
  methods: {
    // openHeadProduct() {
    //   this.$store.dispatch(
    //     'user/changeHeadProductIsShow',
    //     !this.$store.state.user.headProductIsShow
    //   )
    // },
    getCustomExchangeRate() {
      console.log(this.$store.state.user.plants)
      let plat = 'amazon_us'
      if (
        this.$store.state.user.plants &&
        this.$store.state.user.plants.split(',').length == 1
      ) {
        plat = this.$store.state.user.plants.split(',')[0]
      }
      exchangeRateCustom({
        plat: plat,
      })
        .then((res) => {
          if (res.status == 'ok') {
            this.exchangeRateCustomData = res.result
            this.exchangeRateCustomTransRes.custom_rate = res.result.custom_rate
          }
        })
        .catch((err) => {
          this.$message.error(err.backMsg)
        })
    },
    exchangeRateVisibleChange(val) {
      this.exchangeRateCustomTransRes.custom_rate =
        this.exchangeRateCustomData.custom_rate
    },
    setExchangeRateVal(val) {
      this.exchangeRateCustomTransRes.custom_rate = val
    },
    exchangeRateCustomEdit() {
      if (
        !/^[0-9]+(\.[0-9]+)?$/.test(this.exchangeRateCustomTransRes.custom_rate)
      ) {
        this.$message.error('请输入正确的格式')
        return
      }
      if (this.exchangeRateCustomTransRes.custom_rate == 0) {
        this.$message.error('汇率不能为0')
        return
      }
      let plat = 'amazon_us'
      console.log(this.$store.state.user.plants)
      if (
        this.$store.state.user.plants &&
        this.$store.state.user.plants.split(',').length == 1
      ) {
        plat = this.$store.state.user.plants.split(',')[0]
      }
      exchangeRateCustom(
        {
          custom_rate: this.exchangeRateCustomTransRes.custom_rate,
          plat: plat,
        },
        'POST'
      )
        .then((res) => {
          this.$message({
            type: 'success',
            message: '修改成功!',
          })
          exchangeRate({
            plat: plat,
            site_lang: this.$i18n.locale,
          })
            .then((res) => {
              this.exchangeRateList = res.result
              this.changeExchangeRate(this.exchangeRateList[0])
            })
            .catch((err) => {
              this.$message.error(err.backMsg)
            })
        })
        .catch((err) => {
          this.$message.error(err.backMsg)
        })
    },
    getExchangeRate() {
      let plat = 'amazon_us'
      if (
        this.$store.state.user.plants &&
        this.$store.state.user.plants.split(',').length == 1
      ) {
        plat = this.$store.state.user.plants.split(',')[0]
      }
      exchangeRate({
        plat: plat,
        site_lang: this.$i18n.locale,
      })
        .then((res) => {
          this.exchangeRateList = res.result
          this.$store.dispatch(
            'user/changeMoneySymbol',
            this.exchangeRateList[0].currency_symbol
          )
        })
        .catch((err) => {
          this.$message.error(err.backMsg)
        })
    },
    changeExchangeRate(val) {
      if (val == 'custom') return
      if (this.$store.state.user.currency_symbol == val.currency_symbol) return
      exchangeRate(
        {
          currency_en: val.currency_en,
          site_lang: this.$i18n.locale,
        },
        'POST'
      )
        .then((res) => {
          this.$store.dispatch('user/changeMoneySymbol', val.currency_symbol)
          this.$router.go()
        })
        .catch((err) => {
          this.$message.error(err.backMsg)
        })
    },
    userLoginValue(val) {
      const hasloaded = localStorage.getItem('hasloaded') //新手引导组件
      if (hasloaded) {
        this.$store.dispatch('user/changeIsFirstLoad', false)
      } else {
        if (val.is_partner) {
          //oem用户不需要新手引导
          this.$store.dispatch('user/changeIsFirstLoad', false)
        } else {
          localStorage.setItem('hasloaded', true)
          this.$store.dispatch('user/changeIsFirstLoad', true)
        }
      }
      this.loginInfo = val
      const isMoojingUser = val.username.endsWith('@moojing.com')
      this.$store.dispatch('user/changeIsShowChatRobot', isMoojingUser)
      this.$store.dispatch('user/changeLoginInfo', val)
      if (this.loginInfo.download === true) {
        this.$store.dispatch('user/changeShowDownloadBtn', true)
      }
      // 禁用下载或者使用账号符合其一,就禁止显示下载按钮
      // 取消trial_pri_flag的字段判断,由后端统一判断  2023-9-12 cww
      if (this.loginInfo.download === false) {
        this.$store.dispatch('user/changeShowDownloadBtn', false)
      }
      let jdVersionList = localStorage.getItem('jdVersionList')
      let douyinVersionList = localStorage.getItem('douyinVersionList')
      // 防止jdVersion是undefined情况
      if (jdVersionList == 'undefined') {
        jdVersionList = []
      } else {
        jdVersionList = JSON.parse(jdVersionList)
      }
      if (jdVersionList) {
        if (val.jd_version.length == 0) {
          localStorage.setItem('jdVersionList', JSON.stringify([]))
          localStorage.setItem('jdVersion', JSON.stringify(''))
          this.$eventBus.$emit('jdVersionListChange', [])
        } else {
          if (this.isArrayEqual(jdVersionList, val.jd_version)) {
          } else {
            localStorage.setItem(
              'jdVersionList',
              JSON.stringify(val.jd_version)
            )
            localStorage.setItem(
              'jdVersion',
              JSON.stringify(val.jd_version[0] ? val.jd_version[0] : '')
            )
            this.$eventBus.$emit('jdVersionListChange', val.jd_version)
          }
        }
      } else {
        localStorage.setItem('jdVersionList', JSON.stringify(val.jd_version))
        localStorage.setItem(
          'jdVersion',
          JSON.stringify(val.jd_version[0] ? val.jd_version[0] : '')
        )
        this.$eventBus.$emit('jdVersionListChange', val.jd_version)
      }
      // 防止douyinVersion是undefined情况
      if (douyinVersionList == 'undefined') {
        douyinVersionList = []
      } else {
        douyinVersionList = JSON.parse(douyinVersionList)
      }
      if (douyinVersionList) {
        if (val.douyin_version.length == 0) {
          localStorage.setItem('douyinVersionList', JSON.stringify([]))
          localStorage.setItem('douyinVersion', JSON.stringify(''))
          this.$eventBus.$emit('douyinVersionListChange', [])
        } else {
          if (this.isArrayEqual(douyinVersionList, val.douyin_version)) {
          } else {
            localStorage.setItem(
              'douyinVersionList',
              JSON.stringify(val.douyin_version)
            )
            localStorage.setItem(
              'douyinVersion',
              JSON.stringify(val.douyin_version[0] ? val.douyin_version[0] : '')
            )
            this.$eventBus.$emit('douyinVersionListChange', val.douyin_version)
          }
        }
      } else {
        localStorage.setItem(
          'douyinVersionList',
          JSON.stringify(val.douyin_version)
        )
        localStorage.setItem(
          'douyinVersion',
          JSON.stringify(val.douyin_version[0] ? val.douyin_version[0] : '')
        )
        this.$eventBus.$emit('douyinVersionListChange', val.douyin_version)
      }
      //   let { cid, ts } = this.rand_cat(val.cat_list)
      //   let {ts: jd_ts} = this.rand_cat(val.jd_cat_list,'jd')

      //   if(getUrlParams('cid')){
      //     this.cid = getUrlParams('cid')
      //   }else{
      //     this.cid = cid
      //   }
      //   if(getUrlParams('ts')){
      //     this.ts = getUrlParams('ts')
      //   }else{
      //     this.ts = this.plat == 'jd' ? jd_ts : ts
      //   }
      //   this.$store.dispatch('user/changeName', val.username)
      //   this.$store.dispatch('user/changeCatId', this.cid)
      //   this.$store.dispatch('user/changeTs', this.ts)
      if (this.isOversea) {
        this.debounceGetExchangeRate()
        this.getCustomExchangeRate()
      }
    },
    changeLanguage(val) {
      if (this.fenxiLanguage !== val) {
        localStorage.setItem('fenxiLanguage', val)
        this.fenxiLanguage = val
        location.reload()
      }
    },
    isArrayEqual(arr1, arr2) {
      // 先判断数组长度是否相等
      if (arr1.length != arr2.length) {
        return false
      }
      // 数组内所有字符串元素是否相等
      if (
        arr1.filter((item, index) => item == arr2[index]).length == arr1.length
      ) {
        return true
      } else {
        return false
      }
    },
    changeLoginState(val) {},
    //切换语言
    changeLanguge(lan) {
      // this.$i18n.locale = lan
      localStorage.setItem('language', lan)
      store.dispatch('lang/SETLangState', lan)
      location.reload()
    },
    getCustomCat() {
      getCustomCatData({ fulldata: true })
        .then((res) => {
          if (res.status == 'ok') {
            this.$store.dispatch('user/changeCustomCat', res.result.data || [])
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {})
    },
    changeLanguageMode(command) {
      this.languageMode = command
      localStorage.setItem('languageMode', command)
    },
    changeFenxiLanguageMode(command) {
      this.fenxiLanguageMode = command
      localStorage.setItem('fenxiLanguageMode', command)
    },
    changeFenxiLanguage(command) {
      localStorage.setItem('fenxiLanguage', command)
      location.reload()
    },
    changeTheme(command) {
      localStorage.setItem('theme', command)
      location.reload()
    },
    getLocalTheme() {
      const theme = localStorage.getItem('theme')
      if (theme) {
        setTheme(theme)
      } else {
        localStorage.setItem('theme', 'default')
        setTheme('default')
      }
    },
  },
  watch: {
    '$store.state.user.plants': {
      handler(newVal, oldVal) {
        if (this.isOversea) {
          console.log(newVal)
          if (newVal && newVal.split(',').length == 1) {
            console.log('watch')
            this.exchangeRateCustomTransRes.status = 'show'
            this.debounceGetExchangeRate()
            this.getCustomExchangeRate()
          } else {
            this.exchangeRateCustomTransRes.status = 'hide'
          }
        }
      },
    },

    // '$store.state.user.headProductList': {
    //   handler(newVal, oldVal) {
    //     if (newVal.length > 0) {
    //       let arr = _.filter(newVal, function (o) {
    //         return !o.status
    //       })
    //       if (arr.length > 0) {
    //         this.isCompleted = false
    //       } else {
    //         this.isCompleted = true
    //       }
    //     }
    //   },
    //   deep: true,
    // },
    // '$store.state.user.headProductCompletedMove': {
    //   handler(newVal, oldVal) {
    //     console.log(newVal)
    //     this.isCompletedMove = newVal
    //   },
    // },
  },
  created() {
    this.getLocalTheme()
    this.getCustomCat()
    if (!this.lang) {
      this.lang = 'zh'
    }
    if (this.isOversea) {
      this.debounceGetExchangeRate = _.debounce(this.getExchangeRate, 500)
    } else {
      // if (this.$store.state.user.headProductList.length > 0) {
      //   let arr = _.filter(
      //     this.$store.state.user.headProductList,
      //     function (o) {
      //       return !o.status
      //     }
      //   )
      //   if (arr.length > 0) {
      //     this.isCompleted = false
      //   } else {
      //     this.isCompleted = true
      //   }
      // }
    }
    // if (this.isFenxiEn) {
    //   this.fenxiLanguageMode = 'both'
    // }
  },
}
</script>

<style scoped lang='less'>
.header-left {
  min-width: 550px;
  color: @fenxiTopHeaderTextColor;
  .lb {
    display: inline-block;
  }
  .logo {
    img {
      height: 35px;
      margin-top: 10px;
      margin-right: 12px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
    }
    .logoTitle {
      font-size: 16px;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: @fenxiTopHeaderTextColor;
      margin-top: 14px;
      line-height: 20px;
    }
    .logoText {
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: @fenxiTopHeaderTextColor;
      transform: scale(0.7);
      text-align: center;
      line-height: 10px;
    }
  }
  .kf {
    margin-left: 30px;
    cursor: pointer;
    line-height: 56px;
    font-size: 20px;
  }
  .text {
    display: inline-block;
    font-size: 14px;
    vertical-align: top;
    margin-left: 4px;
  }
}
.header-right {
  .el-dropdown-link {
    color: @fenxiTopHeaderTextColor;
  }
}
.header-doc-link {
  color: @fenxiTopHeaderTextColor;
}
.exchange-rate-custom-list {
  display: flex;
  justify-content: space-between;
  .el-icon-circle-check {
    &.checked {
      visibility: visible;
    }
    visibility: hidden;
    line-height: 28px;
    font-size: 18px;
    margin-right: 10px;
    color: #67c23a;
  }
  .exchange-rate-custom-list-left {
    cursor: default;
    input {
      font-size: 14px;
    }
    /deep/ i {
      margin-right: 0;
    }
    p {
      font-size: 14px;
      line-height: 28px;
      cursor: pointer;
      margin: 0;
    }
  }
  .exchange-rate-custom-list-right {
    flex: 1;
    margin-left: 10px;
  }
}
.complete {
  position: relative;
  width: 26px;
  height: 26px;
  margin-left: 10px;
}
.completeImg {
  width: 26px;
  height: 26px;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
}
</style>
