import request from "@/utils/request";
import axios from "axios";
const BASE_API = process.env.VUE_APP_BASE_API;

/**
 *
 * @returns {
 *   ispay: 'yes' || 'nopay' ---- '付费用户' || '测试用户'
 *   trial_pri_flag: Boolean ---- 是否为试用用户
 * }
 */
export function getUserInfo(params) {
  return request({
    url: `${BASE_API}/api/user`,
    method: "get",
    params
  });
}

export function smsSendLogin(data) {
  return request({
    url: `${BASE_API}/api/sms/send/login`,
    method: "post",
    data,
  });
}
export function mailSendLogin(data) {
  return request({
    url: `${BASE_API}/api/mail/send/login`,
    method: "post",
    data,
  });
}
export function smsSendSetting(data) {
  return request({
    url: `${BASE_API}/api/sms/send/setting`,
    method: "post",
    data,
  });
}
export function smsVerifySetting(data) {
  return request({
    url: `${BASE_API}/api/sms/verify/setting`,
    method: "post",
    data,
  });
}
export function smsVerifyLogin(data) {
  return request({
    url: `${BASE_API}/api/sms/verify/login`,
    method: "post",
    data,
  });
}
export function mailVerifyLogin(data) {
  return request({
    url: `${BASE_API}/api/mail/verify/login`,
    method: "post",
    data,
  });
}

