import i18n from "@/components/I18n";
export const tbOptions = {
  cshop: i18n.t("plat.taobao"),
  "cshop,tmall": i18n.t("plat.taobao&tmall"),
  global: i18n.t("plat.global"),
};

export const tmallOptions = {
  tmall: i18n.t("plat.tmall"),
  tmallhk: i18n.t("plat.tmallhk"),
  chaoshi: i18n.t("plat.chaoshi"),
  tmallhk_gfzy: i18n.t("plat.tmallhk_gfzy"),
  nochaoshi: i18n.t("plat.nochaoshi"),
  notmallhk: i18n.t("plat.notmallhk"),
  tmqjd: i18n.t("plat.tmqjd"),
  tmzyzmd: i18n.t("plat.tmzyzmd"),
};

export const jdOptions = {
  jd: i18n.t("plat.jd"),
  jd_only_self: i18n.t("plat.jd_only_self"),
  jd_exclude_self: i18n.t("plat.jd_exclude_self"),
  jd_hk: i18n.t("plat.jd_hk"),
  jd_home_self: i18n.t("plat.jd_home_self"),
  jd_home_pop:i18n.t("plat.jd_home_pop"),
  jd_not_home_self: i18n.t("plat.jd_not_home_self"),
  jd_not_home_pop: i18n.t("plat.jd_not_home_pop"),
};
export const douyinOptions = {
  douyin: i18n.t("plat.douyin"),
};
export const amazonOptions = {
  amazon_us: i18n.t("mainHead.amazon_us"),
  amazon_de: i18n.t("mainHead.amazon_de"),
  amazon_es: i18n.t("mainHead.amazon_es"),
  amazon_fr: i18n.t("mainHead.amazon_fr"),
  amazon_it: i18n.t("mainHead.amazon_it"),
  amazon_uk: i18n.t("mainHead.amazon_uk"),
  amazon_jp: i18n.t("mainHead.amazon_jp"),
  amazon_ae: i18n.t("mainHead.amazon_ae"),
  amazon_sa: i18n.t("mainHead.amazon_sa"),
  amazon_br: i18n.t("mainHead.amazon_br"),
  amazon_mx: i18n.t("mainHead.amazon_mx"),
};
export const shopeeOptions = {
  shopee_vn: i18n.t("mainHead.shopee_vn"),
  shopee_th: i18n.t("mainHead.shopee_th"),
  shopee_sg: i18n.t("mainHead.shopee_sg"),
  shopee_my: i18n.t("mainHead.shopee_my"),
  shopee_ph: i18n.t("mainHead.shopee_ph"),
  shopee_id: i18n.t("mainHead.shopee_id"),
  shopee_tw: i18n.t("mainHead.shopee_tw"),
};
export const lazadaOptions = {
  lazada_vn: i18n.t("mainHead.lazada_vn"),
  lazada_th: i18n.t("mainHead.lazada_th"),
  lazada_sg: i18n.t("mainHead.lazada_sg"),
  lazada_my: i18n.t("mainHead.lazada_my"),
  lazada_ph: i18n.t("mainHead.lazada_ph"),
  lazada_id: i18n.t("mainHead.lazada_id"),
};
export const tiktokOptions = {
  tiktok_gb: i18n.t("mainHead.tiktok_gb"),
  tiktok_id: i18n.t("mainHead.tiktok_id"),
  tiktok_my: i18n.t("mainHead.tiktok_my"),
  tiktok_ph: i18n.t("mainHead.tiktok_ph"),
  tiktok_sg: i18n.t("mainHead.tiktok_sg"),
  tiktok_th: i18n.t("mainHead.tiktok_th"),
  tiktok_us: i18n.t("mainHead.tiktok_us"),
  tiktok_vn: i18n.t("mainHead.tiktok_vn"),
};
export const ozonOptions = {
  ozon_ru: 'ozon',
};
