<template>
  <div class="vue-popper">
    {{content}}
    <div id="arrow"
         data-popper-arrow></div>
  </div>
</template>


<script>
import { createPopper } from '@popperjs/core'
import $ from 'jquery'

export default {
  name: 'Popper',
  data() {
    return {}
  },
  props: {
    // pop id
    popId: {
      type: String,
      default: 'poper-div' + Math.random(),
    },
    // pop content
    content: {
      type: String,
      default: '',
    },
    // pop placement
    placement: {
      type: String,
      default: 'bottom',
    },
    target: null,
  },
  computed: {},
  methods: {
    initPopper() {
      this.$nextTick(() => {
        if (this.target) {
          const tooltip = document.querySelector('#' + this.popId)
          function show() {
            tooltip.style.display = 'block'
          }
          function hide() {
            tooltip.style.display = 'none'
          }
          $(this.target).on('mouseenter', (event) => {
            const target = event.target
            createPopper(target, tooltip, {
              placement: this.placement,
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 8],
                  },
                },
              ],
            })
            show()
          })
          $(this.target).on('mouseleave', (event) => {
            hide()
          })
        }
      })
    },
  },
  created() {},
  mounted() {
    this.$el.setAttribute('id', this.popId)
    this.initPopper()
  },
}
</script>

<style lang="less" scoped>
.vue-popper {
  background: #333;
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 4px 8px;
  font-size: 13px;
  border-radius: 4px;
  display: none;
  z-index: 9999;

  #arrow,
  #arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: -1;
  }

  #arrow::before {
    content: '';
    transform: rotate(45deg);
    background: #333;
  }
}
.vue-popper[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

.vue-popper[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

.vue-popper[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

.vue-popper[data-popper-placement^='right'] > #arrow {
  left: -4px;
}
</style>
