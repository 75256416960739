<template>
  <div class="footer">
    <p style="text-align: center;padding-left: 20px">{{$t('footer.content_one')}} </p>
    <p style="text-align: center;padding-left: 20px"><span>{{$t('footer.content_two')}}</span>
       <a href="https://beian.miit.gov.cn/" target="_blank"><span>{{$t('footer.content_icp')}}</span></a> | 
       <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502037832" target="_blank"><span>{{$t('footer.content_goan')}}</span></a>
    </p>
  </div>
</template>

<script>
const locals = localStorage
export default {
  data() {
    return { locals }
  },
}
</script>

<style>
</style>
