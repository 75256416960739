//import router from "@/router";
import Cookies from "js-cookie";
import { getUrlParams, initLocalName } from "@/utils";

const state = {
  API_TOKEN:
    process.env.NODE_ENV == "development" ? process.env.VUE_APP_API_TOKEN : "", //getToken(),
  name: "", //用户名
  isOversea: window.location.host.includes("oversea") ? true : false, //是否海外版
  // isOversea: true, //是否海外版
  // isOversea: true, //是否海外版
  isDouyin: window.location.host.includes("douyin") ? true : false, //是否抖音版
  // isOversea: true,//是否海外版
  languageMode: localStorage.getItem("languageMode")
    ? localStorage.getItem("languageMode")
    : "system", //语言模式 system , source , both
  fenxiLanguageMode: localStorage.getItem("fenxiLanguageMode")
    ? localStorage.getItem("fenxiLanguageMode")
    : "both", //语言模式 zh , en , both
  moneySymbol:
    window.location.host.includes("oversea") &&
    localStorage.getItem("language") == "en"
      ? "US $"
      : "￥",
  loginInfo: { is_partner: true }, //登陆后的信息
  createdChatBot: false, // 是否创建机器人聊天
  showChatBot: false, // 是否显示机器人聊天框
  chatParams: {
    type: "insight", //唤醒机器人的类型，ai帮写是write,洞察是insight
    post_type: "data", //不同的数据传输类型，发起询问是if_need,发送页面数据是data,客户发送问题是question
    url: location.href, //需要传给后端的页面url
    page_info: {}, //需要传的页面信息json
    tab: "概要&趋势", //当前访问的页面tab
    question: "给我做洞察", //初始化机器人发送的默认问题
    total_page: 1, //后续拓展连续传输可能需要用到的
    page_num: 1, //后续拓展连续传输可能需要用到的
  }, //机器人所需要的参数
  SHowMainHead: false, // 是否显示主页面中的头部栏
  SHowTopHead: true, //是否显示系统顶部栏
  showFilter: false, //是否显示搜索条件栏
  showItemHead: false, //是否显示商品详情页的头部条件栏
  version: getUrlParams("version") ? getUrlParams("version") : "", //优惠券版本
  urlId: getUrlParams("url_id") ? getUrlParams("url_id") : "",
  brandId: getUrlParams("brand_id") ? getUrlParams("brand_id") : "", // 页面顶部选中的品牌类型ID
  brandName: getUrlParams("brand_name") ? getUrlParams("brand_name") : "", // 页面顶部选中的品牌类型名称
  shopId: getUrlParams("shop_id") ? getUrlParams("shop_id") : "", // 页面顶部选中的店铺ID
  shopName: getUrlParams("shop_name") ? getUrlParams("shop、_name") : "", // 页面顶部选中的店铺名称
  cId: getUrlParams("c_id") ? getUrlParams("c_id") : "", //商品详情页需要用的cid
  itemId: getUrlParams("item_id") ? getUrlParams("item_id") : "", //商品详情页需要用的item_id
  subName: getUrlParams("sub_name") ? getUrlParams("sub_name") : "", //商品详情页需要用的sub_name
  itemActivedTab: getUrlParams("itemActivedTab")
    ? getUrlParams("itemActivedTab")
    : "BaseInfo", //商品详情页当前选中的tab
  cats: [], //页面搜索栏选中的类目信息
  catId: getUrlParams("cat_id") ? getUrlParams("cat_id") : "", //选择的类目ID
  catName:
    getUrlParams("cat_id") && getUrlParams("cat_name")
      ? getUrlParams("cat_name")
      : "", //选择的类目名称
  plants: getUrlParams("plat") ? getUrlParams("plat") : "", //选择的平台
  attrvAnd: getUrlParams("attrv_and") ? getUrlParams("attrv_and") : "",
  attrvOr: getUrlParams("attrv_or") ? getUrlParams("attrv_or") : "",
  startMonth: getUrlParams("start") ? getUrlParams("start") : "", //开始月份
  endMonth: getUrlParams("end") ? getUrlParams("end") : "", //结束月份
  ts: localStorage.getItem("isClear")
    ? localStorage.getItem("defaultTs")
      ? JSON.parse(localStorage.getItem("defaultTs"))
      : {}
    : {}, //不同平台下的时间权限
  deadLineStartMonth: "",
  deadLineEndMonth: "",
  //logingState: true, //登陆过状态 false:未登录  true:已登录
  ActivedMnenue: "1-1", //当前选中的menue
  ActivedTab: localStorage.getItem("passActivedTab")
    ? localStorage.getItem("passActivedTab")
    : "Summary", //当前选中的tab
  isSkuGrade: false, //是否为sku级数据
  customCat: [], //自定义类目的权限
  filterParams: {},
  jdVersion: "",
  jdVersionList: [],
  douyinVersion: "",
  douyinVersionList: [],
  customModule: {},
  customRule: {},
  moduleInfo: [],
  showDownloadBtn: true,
  skuDetailItemInfo: {},
  isFirstLoad: false,
  systemTheme: localStorage.getItem("theme"),
  // headProductList: localStorage.getItem("FX_HEAD_PRODUCT_LIST")
  //   ? JSON.parse(localStorage.getItem("FX_HEAD_PRODUCT_LIST"))
  //   : [], //产品价值数据
  // headProductActive: {}, //当前选中的产品价值
  // headProductIsShow: false, //是否显示产品价值栏
  // headProductCompletedMove: false, //触发了解价值点后的动效
  isShowChatRobot: false, //是否显示机器人的帮写或洞察按钮
  customFilingCat: ["custom_103", "custom_104"], // 显示备案的自定义类目
  filingCat: ["1801", "50010788", "20085", "20029"], // 显示备案的常规类目
  filingName: "",
  catTreeNode: "", //当前类目数的dom
  editMode: false,
};

const mutations = {
  SET_PRICE_SUFFIX: (state, moneySymbol) => {
    state.moneySymbol = moneySymbol;
  },
  SET_API_TOKEN: (state, data) => {
    state.API_TOKEN = data;
  },
  SET_LOGIN_INFO: (state, data) => {
    state.loginInfo = data;
  },
  SET_CREATED_CHAT_BOT: (state, data) => {
    state.createdChatBot = data;
  },
  SET_SHOW_CHAT_BOT: (state, data) => {
    state.showChatBot = data;
  },
  SET_CHAT_PARAMS: (state, data) => {
    state.chatParams = data;
  },
  SET_SHOW_MAIN_HEAD: (state, data) => {
    state.SHowMainHead = data;
  },
  SET_SHOW_TOP_HEAD: (state, data) => {
    state.SHowTopHead = data;
  },
  SET_SHOW_FILTER: (state, data) => {
    state.showFilter = data;
  },
  SET_SHOW_ITEM_HEAD: (state, data) => {
    state.showItemHead = data;
  },
  SET_VERSION: (state, data) => {
    state.version = data;
  },
  SET_URL_ID: (state, data) => {
    state.urlId = data;
  },
  SET_BRAND_ID: (state, data) => {
    state.brandId = data;
  },
  SET_BRAND_NAME: (state, data) => {
    state.brandName = data;
  },
  SET_FILING_NAME: (state, data) => {
    state.filingName = data;
  },
  SET_SHOP_ID: (state, data) => {
    state.shopId = data;
  },
  SET_SHOP_NAME: (state, data) => {
    state.shopName = data;
  },
  SET_PLANTS: (state, data) => {
    state.plants = data;
  },
  SET_C_ID: (state, data) => {
    state.cId = data;
  },
  SET_ITEM_ID: (state, data) => {
    state.itemId = data;
  },
  SET_SUB_NAME: (state, data) => {
    state.subName = data;
  },
  SET_ITEM_ACTIVED_TAB: (state, data) => {
    state.itemActivedTab = data;
  },
  SET_CATS: (state, data) => {
    state.cats = data;
  },
  SET_CAT_ID: (state, data) => {
    state.catId = data;
  },
  SET_CAT_NAME: (state, data) => {
    state.catName = data;
  },
  SET_ATTRV_AND: (state, data) => {
    state.attrvAnd = data;
  },
  SET_ATTRV_OR: (state, data) => {
    state.attrvOr = data;
  },
  SET_START_MONTH: (state, data) => {
    state.startMonth = data;
  },
  SET_END_MONTH: (state, data) => {
    state.endMonth = data;
  },
  SET_TS: (state, data) => {
    state.ts = data;
  },
  SET_DEADLINE_START_MONTH: (state, data) => {
    state.deadLineStartMonth = data;
  },
  SET_DEADLINE_END_MONTH: (state, data) => {
    state.deadLineEndMonth = data;
  },
  SET_ACTIVED_MENUE: (state, data) => {
    state.ActivedMnenue = data;
  },
  SET_ACTIVED_TAB: (state, data) => {
    state.ActivedTab = data;
  },
  SET_IS_SKU_GRADE: (state, data) => {
    state.isSkuGrade = data;
  },
  SET_CUSTOMCAT: (state, data) => {
    state.customCat = data;
  },
  SET_FILTERPARAMS: (state, data) => {
    state.filterParams = data;
  },
  SET_JDVERSION: (state, data) => {
    state.jdVersion = data;
  },
  SET_JDVERSIONLIST: (state, data) => {
    state.jdVersionList = data;
  },
  SET_DOUYINVERSION: (state, data) => {
    state.douyinVersion = data;
  },
  SET_DOUYINVERSIONLIST: (state, data) => {
    state.douyinVersionList = data;
  },
  SET_CUSTOMMODULE: (state, data) => {
    state.customModule = data;
  },
  SET_CUSTOMRULE: (state, data) => {
    state.customRule = data;
  },
  SET_MODULEINFO: (state, data) => {
    state.moduleInfo = data;
  },
  SET_LANGUAGE_MODE: (state, data) => {
    state.languageMode = data;
  },
  SET_FENXI_LANGUAGE_MODE: (state, data) => {
    state.fenxiLanguageMode = data;
  },
  SET_MODULEINFO: (state, data) => {
    state.moduleInfo = data;
  },
  SET_SHOW_DOWNLOAD_BTN: (state, data) => {
    state.showDownloadBtn = data;
  },
  SET_SKU_DETAIL_ITEM_INFO: (state, data) => {
    state.skuDetailItemInfo = data;
  },
  SET_IS_FIRST_LOAD: (state, data) => {
    state.isFirstLoad = data;
  },
  // SET_HEAD_PRODUCT_LIST: (state, data) => {
  //   state.headProductList = data;
  // },
  // SET_HEAD_PRODUCT_ACTIVE: (state, data) => {
  //   state.headProductActive = data;
  // },
  // SET_HEAD_PRODUCT_IS_SHOW: (state, data) => {
  //   state.headProductIsShow = data;
  // },
  // SET_HEAD_PRODUCT_COMPLETED_MOVE: (state, data) => {
  //   state.headProductCompletedMove = data;
  // },
  SET_IS_SHOW_CHAT_ROBOT: (state, data) => {
    state.isShowChatRobot = data;
  },
  SET_CAT_TREE_NODE: (state, data) => {
    state.catTreeNode = data;
  },
  SET_EDIT_MODE: (state, data) => {
    state.editMode = data;
  },
};

const actions = {
  changeMoneySymbol({ commit }, moneySymbol) {
    commit("SET_PRICE_SUFFIX", moneySymbol);
  },
  changeApiToken({ commit, dispatch }, data) {
    commit("SET_API_TOKEN", data);
  },
  changeLoginInfo({ commit, dispatch }, data) {
    commit("SET_LOGIN_INFO", data);
  },
  changeCreatedChatBot({ commit, dispatch }, data) {
    commit("SET_CREATED_CHAT_BOT", data);
  },
  changeShowChatBot({ commit, dispatch }, data) {
    commit("SET_SHOW_CHAT_BOT", data);
  },
  changeChatParams({ commit, dispatch }, data) {
    commit("SET_CHAT_PARAMS", data);
  },
  changeShowMainHead({ commit, dispatch }, data) {
    commit("SET_SHOW_MAIN_HEAD", data);
  },
  changeShowTopHead({ commit, dispatch }, data) {
    commit("SET_SHOW_TOP_HEAD", data);
  },
  changeShowFilter({ commit, dispatch }, data) {
    commit("SET_SHOW_FILTER", data);
  },
  changeShowItemHead({ commit, dispatch }, data) {
    commit("SET_SHOW_ITEM_HEAD", data);
  },
  changeVersion({ commit, dispatch }, data) {
    commit("SET_VERSION", data);
  },
  changeUrlId({ commit, dispatch }, data) {
    commit("SET_URL_ID", data);
  },
  changeBrandId({ commit, dispatch }, data) {
    commit("SET_BRAND_ID", data);
  },
  changeBrandName({ commit, dispatch }, data) {
    commit("SET_BRAND_NAME", data);
  },
  changeShopId({ commit, dispatch }, data) {
    commit("SET_SHOP_ID", data);
  },
  changeFilingName({ commit, dispatch }, data) {
    commit("SET_FILING_NAME", data);
  },
  changeShopName({ commit, dispatch }, data) {
    commit("SET_SHOP_NAME", data);
  },
  changeplants({ commit, dispatch }, data) {
    commit("SET_PLANTS", data);
  },
  changeItemId({ commit, dispatch }, data) {
    commit("SET_ITEM_ID", data);
  },
  changeSubName({ commit, dispatch }, data) {
    commit("SET_SUB_NAME", data);
  },
  changeItemActivedTab({ commit, dispatch }, data) {
    commit("SET_ITEM_ACTIVED_TAB", data);
  },
  changeCId({ commit, dispatch }, data) {
    commit("SET_C_ID", data);
  },
  changeCats({ commit, dispatch }, data) {
    commit("SET_CATS", data);
  },
  changeCatId({ commit, dispatch }, data) {
    commit("SET_CAT_ID", data);
  },
  changeCatName({ commit, dispatch }, data) {
    commit("SET_CAT_NAME", data);
  },
  changeAttrvAnd({ commit, dispatch }, data) {
    commit("SET_ATTRV_AND", data);
  },
  changeAttrvOr({ commit, dispatch }, data) {
    commit("SET_ATTRV_OR", data);
  },
  changeStartMonth({ commit, dispatch }, data) {
    commit("SET_START_MONTH", data);
  },
  changeEndMonth({ commit, dispatch }, data) {
    commit("SET_END_MONTH", data);
  },
  changeTs({ commit, dispatch }, data) {
    commit("SET_TS", data);
  },
  changeDeadLineStartMonth({ commit, dispatch }, data) {
    commit("SET_DEADLINE_START_MONTH", data);
  },
  changeDeadLineEndMonth({ commit, dispatch }, data) {
    commit("SET_DEADLINE_END_MONTH", data);
  },
  changeActivedMnenue({ commit, dispatch }, data) {
    commit("SET_ACTIVED_MENUE", data);
  },
  changeActivedTab({ commit, dispatch }, data) {
    commit("SET_ACTIVED_TAB", data);
  },
  changeIsSkuGrade({ commit, dispatch }, data) {
    commit("SET_IS_SKU_GRADE", data);
  },
  changeCustomCat({ commit, dispatch }, data) {
    commit("SET_CUSTOMCAT", data);
  },
  changeFilterParams({ commit, dispatch }, data) {
    commit("SET_FILTERPARAMS", data);
  },
  changeJdVersion({ commit, dispatch }, data) {
    commit("SET_JDVERSION", data);
  },
  changeJdVersionList({ commit, dispatch }, data) {
    commit("SET_JDVERSIONLIST", data);
  },
  changeDouyinVersion({ commit, dispatch }, data) {
    commit("SET_DOUYINVERSION", data);
  },
  changeDouyinVersionList({ commit, dispatch }, data) {
    commit("SET_DOUYINVERSIONLIST", data);
  },
  changeCustomModule({ commit, dispatch }, data) {
    commit("SET_CUSTOMMODULE", data);
  },
  changeCustomRule({ commit, dispatch }, data) {
    commit("SET_CUSTOMRULE", data);
  },
  changeModuleInfo({ commit, dispatch }, data) {
    commit("SET_MODULEINFO", data);
  },
  changeLanguageMode({ commit, dispatch }, data) {
    commit("SET_LANGUAGE_MODE", data);
  },
  changeFenxiLanguageMode({ commit, dispatch }, data) {
    commit("SET_FENXI_LANGUAGE_MODE", data);
  },
  changeModuleInfo({ commit, dispatch }, data) {
    commit("SET_MODULEINFO", data);
  },
  changeShowDownloadBtn({ commit, dispatch }, data) {
    commit("SET_SHOW_DOWNLOAD_BTN", data);
  },
  changeSkuDetailItemInfo({ commit, dispatch }, data) {
    commit("SET_SKU_DETAIL_ITEM_INFO", data);
  },
  changeIsFirstLoad({ commit, dispatch }, data) {
    commit("SET_IS_FIRST_LOAD", data);
  },
  // changeHeadProductList({ commit, dispatch }, data) {
  //   commit("SET_HEAD_PRODUCT_LIST", data);
  // },
  // changeHeadProductActive({ commit, dispatch }, data) {
  //   commit("SET_HEAD_PRODUCT_ACTIVE", data);
  // },
  // changeHeadProductIsShow({ commit, dispatch }, data) {
  //   commit("SET_HEAD_PRODUCT_IS_SHOW", data);
  // },
  // changeHeadProductCompletedMove({ commit, dispatch }, data) {
  //   commit("SET_HEAD_PRODUCT_COMPLETED_MOVE", data);
  // },
  changeIsShowChatRobot({ commit, dispatch }, data) {
    commit("SET_IS_SHOW_CHAT_ROBOT", data);
  },
  changeCatTreeNode({ commit, dispatch }, data) {
    commit("SET_CAT_TREE_NODE", data);
  },
  changeEditMode({ commit, dispatch }, data) {
    commit("SET_EDIT_MODE", data);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
