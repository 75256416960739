/**
 * Created by PanJiaChen on 16/11/18.
 */
import store from "@/store";

import { DateTime } from "luxon";
/**
 *
 * @param {String | Array} plat
 * @returns {String}
 */
export function platSortFenxi(plat) {
  let platStr = "",
    platArr = [],
    newPlatArr = [],
    v1 = "",
    v2 = "",
    v3 = "",
    v4 = "";
  if (typeof plat === "string") {
    platArr = plat.split(",");
  } else if (typeof plat === "object") {
    if (Array.isArray(plat)) {
      platArr = plat;
    }
  }
  newPlatArr = platArr.map((item) => {
    const plat = checkPlatFenxi(item);
    return plat;
  });
  if (newPlatArr.indexOf("taobao") >= 0) {
    v1 = platArr[newPlatArr.indexOf("taobao")];
  }
  if (newPlatArr.indexOf("tmall") >= 0) {
    v2 = platArr[newPlatArr.indexOf("tmall")];
  }
  if (newPlatArr.indexOf("jd") >= 0) {
    v3 = platArr[newPlatArr.indexOf("jd")];
  }
  if (newPlatArr.indexOf("douyin") >= 0) {
    v4 = platArr[newPlatArr.indexOf("douyin")];
  }
  platStr = _.compact([v1, v2, v3, v4]);
  console.log(platStr)
  return platStr.join(",");
}
/**
 *
 * @param {String | Array} plat
 * @returns
 */
export function isMultiPlat(plat) {
  let length = 0,
    platArr = [],
    newPlatArr = [];
  if (typeof plat === "string") {
    platArr = plat.split(",");
  } else if (typeof plat === "object") {
    if (Array.isArray(plat)) {
      platArr = plat;
    }
  }
  length = platArr.length;
  newPlatArr = platArr.map((item) => {
    const plat = checkPlatFenxi(item);
    return plat;
  });
  if (length == 1) {
    return false;
  } else if (length == 2) {
    if (newPlatArr.indexOf("taobao") > -1 && newPlatArr.indexOf("tmall") > -1) {
      return false;
    } else {
      return true;
    }
  } else if (length == 3) {
    return true;
  } else if (length == 4) {
    return true;
  }
}
export function checkPlatFenxi(plat) {
  switch (plat) {
    case "global":
    case "cshop":
      return "taobao";
    case "tmall":
    case "chaoshi":
    case "nochaoshi":
    case "tmallhk":
    case "notmallhk":
    case "tmzyzmd":
    case "tmqjd":
    case "tmallhk_gfzy":
    case "all":
      return "tmall";
    case "jd":
    case "jd_only_self":
    case "jd_exclude_self":
    case "jd_hk":
    case "jd_home_self":
    case "jd_home_pop":
    case "jd_not_home_self":
    case "jd_not_home_pop":
      return "jd";
    case "douyin":
      return "douyin";
    case "amazon_us":
    case "amazon_de":
    case "amazon_es":
    case "amazon_fr":
    case "amazon_it":
    case "amazon_uk":
    case "amazon_jp":
    case "amazon_ae":
    case "amazon_sa":
    case "amazon_br":
    case "amazon_mx":
      return "amazon";
    case "shopee_br":
    case "shopee_cl":
    case "shopee_co":
    case "shopee_es":
    case "shopee_fr":
    case "shopee_id":
    case "shopee_in":
    case "shopee_mx":
    case "shopee_my":
    case "shopee_ph":
    case "shopee_pl":
    case "shopee_sg":
    case "shopee_th":
    case "shopee_tw":
    case "shopee_vn":
      return "shopee";
    case "lazada_my":
    case "lazada_sg":
    case "lazada_th":
    case "lazada_ph":
    case "lazada_id":
    case "lazada_vn":
      return "lazada";
    default:
      return plat;
  }
}
export function plat_region(plat) {
  var region_domain_map = {
    vn: "vn",
    th: "co.th",
    sg: "sg",
    ph: "com.ph",
    my: "com.my",
    id: "co.id",
  };
  var p = {};
  if (plat.indexOf("amazon") >= 0) {
    p.plat = "amazon";
    if (plat == "amazon_jp") {
      p.region = "co.jp";
    } else if (plat == "amazon" || plat == "amazon_us") {
      p.region = "com";
    } else if (plat == "amazon_uk") {
      p.region = "co.uk";
    } else {
      try {
        p.region = plat.split("_")[1];
      } catch (err) {
        p.region = "com";
      }
    }
  } else if (plat.indexOf("shopee") >= 0) {
    p.plat = "shopee";
    if (plat == "shopee_tw") {
      p.region = "xiapi";
    } else {
      try {
        p.region = plat.split("_")[1];
      } catch (err) {
        p.region = "xiapi";
      }
    }
  } else if (plat.indexOf("lazada") >= 0) {
    p.plat = "lazada";
    try {
      var r = plat.split("_")[1];
      p.region = region_domain_map[r];
    } catch (err) {
      p.region = "";
    }
  }else if (plat.indexOf("tiktok") >= 0) {
    p.plat = "tiktok";
    try {
      let r = plat.split("_")[1];
      p.region = r.toUpperCase();
    } catch (err) {
      p.region = "";
    }
  } else {
    p.plat = plat;
    p.region = "";
  }
  return p;
}
//根据开始月和结束月返回中间的月
export function build_ts(max, min) {
  var monthes = [];
  var min = min || "2017-01";
  var timeArr = max.split("-");
  var lastMonth = new Date(timeArr[0], timeArr[1] - 1, 1);
  timeArr = min.split("-");
  var monthIter = new Date(timeArr[0], timeArr[1] - 1, 1);
  while (monthIter.getTime() <= lastMonth.getTime()) {
    var m = monthIter.getMonth() + 1;
    monthes.unshift(monthIter.getFullYear() + "-" + (m > 9 ? m : "0" + m));
    monthIter.setMonth(monthIter.getMonth() + 1);
  }
  return monthes;
}
/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    if (typeof time === "number") {
      // 毫秒格式的处理
      date = DateTime.fromMillis(time, { zone: "Asia/Shanghai" });
    } else {
      // ISO格式的处理
      date = DateTime.fromISO(time, { zone: "Asia/Shanghai" });
    }
  }
  const formatObj = {
    y: date.year,
    m: date.month,
    d: date.day,
    h: date.hour,
    i: date.minute,
    s: date.second,
    a: date.day,
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}
/**
 *
 * @param {number} ts
 * @returns Q1 / Q2 / Q3 / Q4
 */
export function getQuarterFromTimestamp(ts) {
  const date = DateTime.fromMillis(ts, { zone: "Asia/Shanghai" });
  const year = date.year;
  const month = date.month;
  let quarter;
  if (month >= 1 && month <= 3) {
    quarter = "Q1";
  } else if (month >= 4 && month <= 6) {
    quarter = "Q2";
  } else if (month >= 7 && month <= 9) {
    quarter = "Q3";
  } else {
    quarter = "Q4";
  }
  return `${year}-${quarter}`;
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf("?") + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {string} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

/**
 * @param {Array} actual
 * @returns {Array}
 */
export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

/**
 * @param {Object} json
 * @returns {Array}
 */
export function param(json) {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return "";
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
}

/**
 * @param {string} url
 * @returns {Object}
 */
export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, " ") +
      '"}'
  );
}

/**
 * @param {string} val
 * @returns {string}
 */
export function html2Text(val) {
  const div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */
export function objectMerge(target, source) {
  if (typeof target !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

/**
 * @param {HTMLElement} element
 * @param {string} className
 */
export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

/**
 * @param {Array} arr
 * @returns {Array}
 */
export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

/**
 * @returns {string}
 */
export function createUniqueString() {
  const timestamp = +new Date() + "";
  const randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}

/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */
export function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}

/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}

/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */
export function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}

/***
 * 将对象转为数组，仅限一层键值对
 */
export function coverObjToArr(obj) {
  return Object.keys(obj).map(function (i) {
    return { name: i, value: obj[i] }; // obj[i];
  });
}
export function threeDigitsPercentage(data) {
  //数字转百分比保留小数点后3位不会4舍5入
  let data1 = Math.floor(data * 10000) / 100 + "%";
  return data1;
}
/***
 *获取浏览器地址栏参数
 */
export function getUrlParams(params) {
  var urlObj = {};
  if (!window.location.hash.split("?")[1]) {
    return false;
  }
  var urlParams = window.location.hash.split("?")[1];
  var urlArr = urlParams.split("&");
  for (var i = 0; i < urlArr.length; i++) {
    var urlArrItem = urlArr[i].split("=");
    if (urlArrItem[0] == "plat") {
    }
    urlObj[urlArrItem[0]] = decodeURIComponent(urlArrItem[1]);
  } // 判断是否有参数
  if (arguments.length >= 1) {
    return urlObj[params];
  }
  return urlObj;
}

//设置浏览器地址栏参数
export function setUrlParams(Url, params, val) {
  if (!Url.split("?")[1]) {
    return Url + "?" + params + "=" + val;
  }
  var urlParams = Url.split("?")[1];
  var urlArr = urlParams.split("&");
  let str = "";
  if (urlParams.indexOf(params) < 0) {
    return Url + "&" + params + "=" + val;
  } else {
    for (var i = 0; i < urlArr.length; i++) {
      var urlArrItem = urlArr[i].split("=");
      if (urlArrItem[0] == params) {
        str = urlArrItem[0] + "=" + urlArrItem[1];
        return Url.replace(str, params + "=" + val);
      }
    }
  }
}

function filter(str) {
  // 特殊字符转义
  str += ""; // 隐式转换
  str = str.replace(/%/g, "%25");
  str = str.replace(/\+/g, "%2B");
  str = str.replace(/ /g, "%20");
  str = str.replace(/\//g, "%2F");
  str = str.replace(/\?/g, "%3F");
  str = str.replace(/&/g, "%26");
  str = str.replace(/\=/g, "%3D");
  str = str.replace(/#/g, "%23");
  return str;
}

export function formateObjToParamStr(paramObj) {
  const sdata = [];
  for (let attr in paramObj) {
    sdata.push(`${attr}=${filter(paramObj[attr])}`);
  }
  return sdata.join("&");
}

//从浏览器地址栏中读取参数
export function getObjFromUrl(url) {
  var args = url.split("?");
  if (args[0] == url) {
    return "";
  }
  var arr = args[1].split("&");
  var obj = {};
  for (var i = 0; i < arr.length; i++) {
    var arg = arr[i].split("=");
    obj[arg[0]] = arg[1];
  }
  return obj;
}

//获取指定年月的上一个月
export function getLastMonth(data) {
  var year, lastMonth;
  var date = new Date(data);
  var nowYear = date.getFullYear();
  var nowMonth = date.getMonth();
  if (nowMonth == 0) {
    year = nowYear - 1;
    lastMonth = 12;
  } else {
    year = nowYear;
    lastMonth = nowMonth;
  }
  lastMonth = lastMonth < 10 ? "0" + lastMonth : lastMonth;
  let lastYearMonth = year + "-" + lastMonth;
  return lastYearMonth;
}

//处理品牌下拉选项问题
export function handelBrandNameOptions(newVal, brandOptions) {
  /* if (!brandOptions) {
    return [];
  }*/
  let options = brandOptions;
  if (!newVal || !newVal.length) {
    return options;
  } else {
    let otherOptions = store.state.user.brandName;
    let defaultBrandOptions = localStorage.getItem("defaultBrandOption")
      ? JSON.parse(localStorage.getItem("defaultBrandOption"))
      : [];
    let arr = [...brandOptions, ...otherOptions, ...defaultBrandOptions];
    let newArr = [];
    arr.map((item) => {
      let isExit = newArr.find((item2) => {
        if (item2.id == item.id) {
          return item2;
        }
      });
      if (!isExit) {
        newArr.push(item);
      }
    });
    return newArr;
  }
}

//打开机器人方法
export function openChatBot(newVal, brandOptions) {
  if (store.state.user.createdChatBot) {
    store.dispatch("user/changeShowChatBot", true);
  } else {
    store.dispatch("user/changeCreatedChatBot", true);
    store.dispatch("user/changeShowChatBot", true);
  }
  let botParam = Object.assign({}, store.state.user.chatParams);
  botParam.type = "insight";
  botParam.question = "给我做洞察";
  botParam.url = location.href;
  store.dispatch("user/changeChatParams", botParam);
}
//两个数组合并去重
export function MergeDeduplicationArray(arr1, arr2) {
  let oldBrandOptions1 = JSON.parse(JSON.stringify(arr1));
  let oldBrandOptions2 = JSON.parse(JSON.stringify(arr2));
  let newOptions = [];
  let a = [...oldBrandOptions1, ...oldBrandOptions2];
  let b = new Set(a);
  newOptions = [...b];
  return newOptions;
}

//将数字转为千分位
export function transformNum(val) {
  return val.toString().replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, "$1,");
}

//根据当前浏览器地址中获取的id设置相应的name(目前只有brand品牌用到)，用于解决location带来的无法session隔离的问题
export function initLocalName(ids, names) {
  let NewNames = [];
  if (ids && ids.length && names && names.length) {
    NewNames = names.filter((item) => {
      return ids.find((id) => {
        return id == item.id;
      });
    });
  }
  return NewNames;
}

//设置当前tab
export function setActivedTab(val) {
  if (store.state.user.ActivedTab != val) {
    store.dispatch("user/changeActivedTab", val);
  }
}
//两个对象的深度合并
export function deepMerge(obj1, obj2) {
  let key;
  for (key in obj2) {
    obj1[key] =
      obj1[key] &&
      typeof obj1[key] === "object" &&
      obj2[key] &&
      typeof obj2[key] === "object"
        ? deepMerge(obj1[key], obj2[key])
        : (obj1[key] = obj2[key]);
  }
  return obj1;
}

export function jumpToBlank(link) {
  return new Promise((resolve,reject) => {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = link
      elink.target = '_blank'
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
      resolve({done: true})
    })
}

//设置y轴最大值
export function setYAxisMaxVal(arrayF) {
  let flatten = (arr) => {
    while (arr.some((item) => Array.isArray(item))) {
      arr = [].concat(...arr);
    }
    return arr;
  };
  let returnMaxValue = (array, referMax) => {
    try {
      if (Math.max(...array.flat(Infinity)) > referMax) {
        referMax =
          referMax -
          (Math.max(...array.flat(Infinity)) % referMax) +
          Math.max(...array.flat(Infinity));
      }
      return referMax;
    } catch (error) {
      if (Math.max(...flatten(array)) > referMax) {
        referMax =
          referMax -
          (Math.max(...flatten(array)) % referMax) +
          Math.max(...flatten(array));
      }
      return referMax;
    }
  };
  let maxValue = 10;
  maxValue = returnMaxValue(arrayF, maxValue);
  var big_val = maxValue
    ? formatInt(maxValue, (Math.round(maxValue) + "").length - 2)
    : 100;
  return big_val;
}

export function setYAxisMinVal(obj, isArr = false) {
  let small_val = 0;
  if (isArr) {
    small_val = 100000000;
    obj.forEach((item) => {
      if (item < small_val) {
        small_val = item;
      }
    });
  } else {
    small_val = 0;
    Object.keys(obj).forEach((item) => {
      obj[item].forEach((ele) => {
        if (ele * 100 < small_val) {
          small_val = ele * 100;
        }
      });
    });
  }
  return Math.floor(small_val);
}

export function setYAxisMaxVal_1(obj) {
  let small_val = 0;
  small_val = 0;
  Object.keys(obj).forEach((item) => {
    if (item.indexOf("yoy") > -1 || item.indexOf("qoq") > -1) {
      obj[item].forEach((ele) => {
        if (ele * 100 > small_val) {
          small_val = ele * 100;
        }
      });
    }
  });

  return Math.ceil(small_val);
}

export function setBarMaxVal(array) {
  const arr = array.filter((item) => item != "-");
  const max = Math.max(...arr);
  let result = Math.ceil((max * 100) / 3) * 3;
  if (result < 0) {
    return 0;
  } else {
    return result;
  }
}

export function setBarMinVal(array) {
  const arr = array.filter((item) => item != "-");
  const min = Math.min(...arr);
  let result = Math.floor((min * 100) / 3) * 3;
  if (result > 0) {
    return 0;
  } else {
    return result;
  }
}

export function formatInt(num, prec = 2, ceil = true) {
  const len = String(num).length;
  if (len <= prec) {
    return num;
  }

  const mult = Math.pow(10, prec);
  return ceil ? Math.ceil(num / mult) * mult : Math.floor(num / mult) * mult;
}
export function transformAmount(value, type = "ch") {
  if (!value) return 0;
  if (localStorage.getItem("language") == "en" || type == "en") {
    if (value > 999999999) {
      value = (value / 1000000000).toFixed(2) + "B";
    } else if (value > 999999) {
      value = (value / 1000000).toFixed(2) + "M";
    } else if (value > 999) {
      value = (value / 1000).toFixed(2) + "K";
    } else {
      value = parseInt(value, 10) + "";
    }
    value = "US $" + value;
  } else {
    if (value > 99999999) {
      value = (value / 100000000).toFixed(2) + "亿";
    } else if (value > 999999) {
      value = (value / 10000).toFixed(2) + "万";
    } else if (value > 99999) {
      value = (value / 10000).toFixed(2) + "万";
    } else if (value > 9999) {
      value = (value / 10000).toFixed(2) + "万";
    } else {
      value = parseFloat(value).toFixed(2);
    }
    value = (store.state.user.moneySymbol || "￥") + value;
  }
  return value;
}

export function transformNumber(value) {
  if (!value) return 0;
  if (localStorage.getItem("language") == "en") {
    if (value > 999999999) {
      value = (value / 1000000000).toFixed(2) + "B";
    } else if (value > 999999) {
      value = (value / 1000000).toFixed(2) + "M";
    } else if (value > 999) {
      value = (value / 1000).toFixed(2) + "K";
    } else {
      value = parseInt(value, 10) + "";
    }
  } else {
    if (value > 99999999) {
      value = (value / 100000000).toFixed(2) + "亿";
    } else if (value > 999999) {
      value = (value / 10000).toFixed(2) + "万";
    } else if (value > 99999) {
      value = (value / 10000).toFixed(2) + "万";
    } else if (value > 9999) {
      value = (value / 10000).toFixed(2) + "万";
    } else {
      value = parseFloat(value).toFixed(2);
    }
  }
  return value;
}
export function computeChartNumber(value, divisor, unit) {
  // divisor 除数 unit 单位
  value = (value / divisor).toFixed(1).toString()
  if (value.split('.')[1] == 0) {
    value = value.split('.')[0]
  }
  value = value + unit;
  return value
}
export function transformNumberChart(value) {
  if (!value) return 0;
  if (localStorage.getItem("language") == "en") {
    if (value > 999999999) {
      value = computeChartNumber(value, 1000000000, "B");
    } else if (value > 999999) {
      value = computeChartNumber(value, 1000000, "M");
    } else if (value > 999) {
      value = computeChartNumber(value, 1000, "K");
    } else {
      value = parseInt(value, 10) + "";
    }
  } else {
    if (value > 99999999) {
      value = computeChartNumber(value, 100000000, "亿");
    } else if (value > 999999) {
      value = computeChartNumber(value, 10000, "万");
    } else if (value > 99999) {
      value = computeChartNumber(value, 10000, "万");
    } else if (value > 9999) {
      value = computeChartNumber(value, 10000, "万");
    } else {
      value = parseFloat(value);
    }
  }
  return value;
}
export function getMoneySymbol(plat) {
  if (localStorage.getItem("language") == "en") {
    return "US $";
  } else {
    return "￥";
  }
}

// 图表下载功能
export function chartDownloadToPng(fileName, content) {
  let aLink = document.createElement("a");
  let blob = base64ToBlob(content); // new Blob([content]);
  aLink.download = fileName;
  aLink.href = URL.createObjectURL(blob);
  document.body.appendChild(aLink);
  aLink.click();
  document.body.removeChild(aLink);
}
export function base64ToBlob(code) {
  let parts = code.split(";base64,");
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]); // 解码base64得到二进制字符串
  let rawLength = raw.length;
  let uInt8Array = new Uint8Array(rawLength); // 创建8位无符号整数值的类型化数组
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i); // 数组接收二进制字符串
  }
  return new Blob([uInt8Array], { type: contentType });
}

/**
 * @description 用双引号包裹指定字符串
 * @param {String} str
 * @returns String
 */
export function wrapInDoubleQuotes(str) {
  return '"' + str + '"';
}

/**
 * @description 去除双引号包裹的字符串
 * @param {String} str
 * @returns String
 */
export function removeDoubleQuotes(str) {
  let result = str.trim();
  if (result.startsWith('"') && str.endsWith('"')) {
    return result.slice(1, -1);
  }
  return result;
}

export function getBaseErrorObj(){
  const url = window.location.href
  const ua = navigator.userAgent
  const tab = store.state.user.ActivedTab
  const browser_finger = localStorage.getItem('browserFinger')
  console.log(url, browser_finger,store)
  return {
    error_url: url,
    ua,
    tab,
    browser_finger
  }
}
// 是否请求超时报错
export function isTimeoutError(error) {
  // axios 超时报错代码
  if (error.code === 'ECONNABORTED') {
      return true
  }else{
    return false
  }
}
