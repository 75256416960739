<template>
  <div>
    <el-popover v-model="visible"
                :disabled='disabled'>
      <template v-if="(startDate && endDate) || (!startDate && !endDate)">
        <div class="month-picker-cpn"
             :class="{disabled: disabled}"
             slot="reference">
          <span class="iconfont-calendar"></span>
          <PickerInput :dateTime="startDate"
                       :disabled="disabled" />
          <span class="el-range-separator">{{$t('search.to')}}</span>
          <PickerInput :dateTime="endDate"
                       :disabled="disabled" />
        </div>
      </template>
      <template v-else-if="startDate && !endDate">
        <div class="month-picker-cpn"
             :class="{disabled: disabled}"
             slot="reference">
          <span class="iconfont-calendar"></span>
          <div style="line-height: 20px;width: 300px;text-align: center">{{startDate}}之后</div>
        </div>
      </template>
      <template v-else-if="!startDate && endDate">
        <div class="month-picker-cpn"
             :class="{disabled: disabled}"
             slot="reference">
          <span class="iconfont-calendar"></span>
          <div style="line-height: 20px;width: 300px;text-align: center">{{endDate}}之前</div>
        </div>
      </template>
      <div v-if="visible"
           :class="$i18n.locale == 'en' ? 'en-font' : ''">
        <PickerPanel :dateTime="[startDate, endDate]"
                     :default-max-date="maxDate"
                     :default-min-date="minDate"
                     :can-empty="canEmpty"
                     @on-changeTime="changeTime"
                     @on-cancleChange="cancleChange" />
      </div>

    </el-popover>

  </div>
</template>

<script>
import PickerInput from './PickerInput.vue'
import PickerPanel from './PickerPanel.vue'
export default {
  name: 'MonthPickerCpn',
  model: {
    prop: 'dateTime',
    event: 'change',
  },
  props: {
    dateTime: {
      type: Array,
      default: () => [],
    },
    maxDate: {
      type: String,
      default: '',
    },
    minDate: {
      type: String,
      default: '',
    },
    canEmpty: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PickerInput,
    PickerPanel,
  },
  data() {
    return {
      visible: false,
      startDate: '',
      endDate: '',
      showPop: true,
    }
  },
  methods: {
    showPickerPanel() {
      this.visible = !this.visible
    },
    changeTime(dateTime) {
      this.startDate = dateTime[0]
      this.endDate = dateTime[1]
      this.$emit('change', dateTime)
      this.visible = false
    },
    cancleChange() {
      this.visible = false
    },
  },
  created() {
    this.startDate = this.dateTime[0]
    this.endDate = this.dateTime[1]
  },
  watch: {
    dateTime: {
      handler(newVal, oldVal) {
        this.startDate = this.dateTime[0]
        this.endDate = this.dateTime[1]
        this.$emit('change', this.dateTime)
      },
    },
  },
}
</script>

<style lang="less" scoped>
.month-picker-cpn {
  display: flex;
  height: 30px;
  line-height: 30px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 3px 10px;

  .el-range-separator {
    line-height: 22px;
  }

  .iconfont-calendar {
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 20px;
  }
  .iconfont-calendar::before {
    font-family: 'iconfont';
    content: '\e600';
  }
}
.disabled {
  cursor: not-allowed;
}
</style>
