import Vue from "vue";
import VueI18n from "vue-i18n";
import enElement from "element-ui/lib/locale/lang/en";
import zhElement from "element-ui/lib/locale/lang/zh-CN";
import zhLocal from "@/common/lang/zh";
import enLocal from "@/common/lang/en";
import store from "@/store";
Vue.use(VueI18n);
const messages = {
  en: {
    ...enLocal,
    ...enElement,
  },
  zh: {
    ...zhLocal,
    ...zhElement,
  },
};
let defaultLang = localStorage.getItem("language")
  ? localStorage.getItem("language") :
  store.state.user.isOversea ? 'zh' : "zh"; "zh"; //getCookie('PLAY_LANG', 'zh'), // 语言标识
if(store.state.user.isOversea){
  // 暂时海外都给zh
  defaultLang = 'zh'
}
const fenxiLanguage = localStorage.getItem('fenxiLanguage')
if(fenxiLanguage){
  defaultLang = fenxiLanguage
}
const i18n = new VueI18n({
  locale: defaultLang, //
  messages,
});
localStorage.setItem('language', defaultLang);
store.dispatch('lang/SETLangState',defaultLang)
export const translate = (localeKey) => {
  const locale = defaultLang;
  const hasKey = i18n.te(localeKey, locale)  // 使用i18n的 te 方法来检查是否能够匹配到对应键值
  const translatedStr = i18n.t(localeKey)
  if (hasKey) {
    return translatedStr
  }
  return localeKey
}

export default i18n;
