<template>
  <div class="cost-reduce">
    <img src="../assets/aside-ai.png"
         alt=""
         style="width: 100%;height: 100%"
         @load="moreLinkShow = true">
    <div class="more_link more_link_1"
         v-show="moreLinkShow">
      <a :href="more_url"
         target="_black">
        了解更多
      </a>
    </div>
    <div class="more_link more_link_2"
         v-show="moreLinkShow">
      <a :href="more_url"
         target="_black">
        了解更多
      </a>
    </div>
    <div class="more_link more_link_3"
         v-show="moreLinkShow">
      <a :href="more_url"
         target="_black">
        了解更多
      </a>
    </div>
    <div class="more_link more_link_4"
         v-show="moreLinkShow">
      <a :href="more_url"
         target="_black">
        了解更多
      </a>
    </div>
  </div>
</template>


<script>
export default {
  // 降本增效的弹窗
  name: 'CostReduce',
  data() {
    return {
      moreLinkShow: false,
      more_url:
        'https://qmctsz2s8t.feishu.cn/drive/folder/fldcnkfC6tKxhICr6ihzg17p7Be?from=space_personal_filelist&fromShareWithMeNew=1',
    }
  },
  props: {},
  computed: {},
  methods: {},
  created() {},
}
</script>

<style lang="less" scoped>
.cost-reduce {
  width: 1000px;
  height: 563px;
  border-radius: 5px;
  position: relative;

  .more_link_1 {
    top: 240px;
    left: 420px;
  }
  .more_link_2 {
    top: 330px;
    right: 75px;
  }
  .more_link_3 {
    top: 430px;
    left: 420px;
  }
  .more_link_4 {
    top: 500px;
    right: 75px;
  }
  .more_link {
    position: absolute;
  }
  .more_link a {
    color: #3c77f2;
    font-weight: bold;
  }
}
</style>
