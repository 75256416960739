<template>
  <div v-if="isCreated"
       v-show="isShow"
       class="dialogBox"
       :class="styleClass">
    <div class="head">
      <div class="tabs">
        <div class="item"
             :class="{'b_line':params['type'] == 'insight','noAllowed':params['tab'] == 'search'}">
          <div :class="{'noClick':params['tab'] == 'search'}"
               @click="tabChange('insight')">解读</div>
        </div>
        <div class="item"
             :class="params['type'] == 'write'?'b_line':''"
             @click="tabChange('write')">过滤规则帮写</div>
        <div class="item">
          <el-tag size="mini">实验</el-tag>
        </div>
        <!-- <div class="item"
             v-if="conv_id">
          <el-button size="mini"
                     style="padding: 7px"><a :href="'https://marketbottest.mktindex.com/#/app?id='+ conv_id"
               target="_blank"><i class="el-icon-top-right"></i>跳转至镜界AI</a></el-button>
        </div> -->
      </div>
      <!-- <div class="closeBtn"
         @click="closeFn">×</div> -->
      <div class="closeBtn"
           @click="hideFn"><i class="iconfont icon-web__xiala"></i></div>
    </div>
    <div id="chatRobotBox">
      <div id="chatRobot"></div>
    </div>
  </div>
</template>
<script>
import { Bubbles, prepHTML } from './bubbles/Bubbles.js'
import request from '@/utils/request'

export default {
  components: {},
  props: {
    styleClass: {
      type: String,
      default: '',
    },
    wsUrl: {
      type: String,
      default: 'wss://chatbot.mktindex.com/ws',
    },
    loginInfo: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isCreated: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chatWindow: null,
      convo: {
        robot: {
          says: [
            '您可以用自然语言描绘过滤条件，我可以帮您输出逻辑语言，如输入“包含安踏或者anta，但是不包含‘儿童’或者kids”，会输出"(安踏 or anta) and not (儿童 or kids)"',
          ],
          copy: false,
          //   reply: [
          //     {
          //       question: 'User',
          //       answer: 'user',
          //     },
          //   ],
        },
      },
      insightText: '我可以回答您针对当前页面数据提出的任何问题，您想了解什么？',
      insightUserText: '请依据当前Tab页面数据，帮我做市场解读',
      writeText:
        '您可以用自然语言描绘过滤条件，我可以帮您输出逻辑语言，如输入“包含安踏或者anta，但是不包含‘儿童’或者kids”，会输出"(安踏 or anta) and not (儿童 or kids)',
      connectText: '正在连接服务器......',
      websock: null,
      params: {},
      timeout: 30000, //每30秒发送心跳检测
      timeoutObj: null,
      counter: 0,
      intervalId: null,
      isLock: false, //是否上锁
      isConnectWs: false, //是否连接上了ws
      defaultQuestionData: [],
    }
  },
  created() {
    this.initWebSocket()
  },
  watch: {
    isConnectWs(newVal, oldVal) {
      if (newVal == true && this.params == 'insight') {
        let obj = Object.assign({}, this.params)
        let objs = _.omit(obj, ['type'])
        this.websocketsend(JSON.stringify(objs))
      }
    },
    isCreated(newVal, oldVal) {
      if (newVal == true) {
        console.log('isCreated', this.params)
        this.$nextTick(() => {
          //   this.initWebSocket()
          this.initChatBubble()
          prepHTML({
            container: 'chatRobot',
            parent_id: 'chatRobotBox',
          })
        })
      }
    },
    isShow(newVal, oldVal) {
      if (newVal == true && this.isCreated) {
        this.$nextTick(() => {
          console.log('isShow', this.params.question)
          const parent = document.querySelector('.bubble-container')
          const child = parent.querySelector('.bubble-wrap')
          child.scrollTop = child.scrollHeight
          if (this.params.type == 'insight') {
            this.getDefaultQuestionData()
          }
          if (this.params.type == 'write') {
            // this.chatGptReply(this.params.question, false)
          }
        })
      }
    },
    '$store.state.user.chatParams': {
      handler(newVal, oldVal) {
        let self = this
        this.params = Object.assign(
          { username: this.loginInfo.username },
          newVal
        )
        this.params.url = location.href
        console.log('chatParams', this.params)
        if (
          (oldVal.tab != newVal.tab || oldVal.type != newVal.type) &&
          newVal.type == 'insight' &&
          this.isShow
        ) {
          this.getDefaultQuestionData()
        }
        if (
          oldVal.type != newVal.type &&
          this.isShow &&
          this.isConnectWs &&
          !this.isLock &&
          this.chatWindow
        ) {
          if (newVal.type == 'insight') {
            this.chatGptReply(this.insightText, false)
            let obj = Object.assign({}, newVal)
            let objs = _.omit(obj, ['type'])
            this.websocketsend(JSON.stringify(objs))
          } else if (newVal.type == 'write') {
            this.chatGptReply(this.writeText, false)
          }
        }
      },
    },
    $route: {
      handler(newVal, oldVal) {
        this.params.url = location.href
      },
      deep: true,
    },
  },
  mounted() {},
  beforeDestroy() {
    // clearInterval(this.intervalId)
    clearInterval(this.timeoutObj)
  },
  destroyed() {
    console.log('close')
  },
  methods: {
    getDefaultQuestionData() {
      //获取机器人提供默认的问题列表
      //   let config = {
      //     url: 'https://marketbot.moojing.com/helper/' + this.params.tab,
      //     method: 'get',
      //     params: {},
      //   }
      //   request(config)
      //     .then((res) => {
      //       console.log(res)
      //       if (res.status == 'ok' && res.result.data.length > 0) {
      //         this.defaultQuestionData = res.result.data
      //         console.log(this.defaultQuestionData)
      //         this.chatWindow.setDefaultQuestion(res.result)
      //       }
      //     })
      //     .catch((e) => {
      //       console.log(e)
      //     })
      let question = JSON.stringify({
        post_type: 'helper',
        tab: this.params.tab,
      })
      this.websock.send(question)
    },
    tabChange(val) {
      var self = this
      if (this.params.type == val) {
        return
      } else {
        this.params.type = val
      }
      if (this.isLock || !this.isConnectWs) {
        return
      }
      if (val == 'insight' && this.$route.name == 'detail') {
        this.chatGptReply(this.insightText, false)
        this.params.question = '给我做洞察'
        let obj = Object.assign({}, this.params)
        let objs = _.omit(obj, ['type'])
        self.websocketsend(JSON.stringify(objs))
        this.getDefaultQuestionData()
      } else if (val == 'write') {
        this.chatGptReply(this.writeText, false)
        this.chatWindow.setDefaultQuestion({ data: [] })
      }
    },
    hideFn() {
      this.$emit('changeShowChatBot', false)
    },
    closeFn() {
      this.$emit('changeCreatedChatBot', false)
    },
    reconnect() {
      //重连
      //   this.intervalId && clearInterval(this.intervalId)
      //   this.intervalId = setInterval(() => {
      //     if (this.counter < 10) {
      //       //最多重连10次
      //       // 执行WebSocket重连方法
      //       this.initWebSocket()
      //       this.counter++
      //     } else {
      //       console.log('无法连接服务，请刷新浏览器后重试')
      //       clearInterval(this.intervalId)
      //       this.chatGptReply(
      //         '无法连接服务，请刷新浏览器后重试',
      //         false
      //       )
      //       this.chatWindow.lock(true)
      //       this.isLock = true
      //     }
      //   }, 3000) // 每3秒执行一次

      if (this.counter < 10) {
        //最多重连10次
        // 执行WebSocket重连方法
        this.initWebSocket()
        this.counter++
      } else {
        console.log('已经重连10次，无法连接服务，请刷新浏览器后重试')
        if (this.chatWindow) {
          this.chatGptReply('无法连接服务，请刷新浏览器后重试', false)
          this.chatWindow.lock(true)
          this.isLock = true
        }
      }
    },
    heartCheck() {
      var self = this
      this.timeoutObj && clearInterval(this.timeoutObj)
      this.timeoutObj = setInterval(function () {
        let heatbeat = JSON.stringify({ post_type: 'beat' })
        self.websock.send(heatbeat)
        console.log('发送了心跳')
      }, self.timeout)
    },
    initChatBubble() {
      //初始化聊天机器人方法
      let self = this
      this.chatWindow = new Bubbles(
        document.getElementById('chatRobot'),
        'chatWindow',
        {
          inputCallbackFn: function (o) {
            self.inputCallbackFn(o)
          },
          copyCallbackFn: function (o) {
            self.copyCallbackFn(o)
          },
          defaultQuestionCallbackFn: function (o) {
            self.defaultQuestionCallbackFn(o)
          },
          replyCallbackFn: function (o, c) {
            self.replyCallbackFn(o, c)
          },
        }
      )
      window.chatWindow = this.chatWindow
      this.$nextTick(() => {
        this.initQuestion()
      })
    },
    inputCallbackFn(o) {
      //输入框问题发送
      let self = this
      this.params.question = o.input
      let datas = _.omit(this.params, ['type'])
      console.log(datas)
      self.websocketsend(JSON.stringify(datas))
      this.autoReply()
    },
    copyCallbackFn(val) {
      //点击复制
      let inputElement = document.createElement('input')
      inputElement.value = val
      document.body.appendChild(inputElement)
      inputElement.select() //选中文本
      document.execCommand('copy') //执行浏览器复制命令
      inputElement.remove()
      this.$message({
        message: '复制成功',
        type: 'success',
      })
    },
    defaultQuestionCallbackFn(index) {
      //点击默认问题发送
      let self = this
      if (this.isLock) {
        return
      }
      let val = this.defaultQuestionData[index]
      self.chatWindow.activelySend(val)
      this.params.question = val
      let datas = _.omit(this.params, ['type'])
      console.log(datas)
      self.websocketsend(JSON.stringify(datas))
      this.autoReply()
    },
    replyCallbackFn(val, copy) {
      //内容渲染后
      console.log(val, this.insightText)
      if (val[0] == this.insightText) {
        console.log(this.insightUserText)
        this.chatWindow.activelySend(this.insightUserText)
        this.autoReply()
      }
      if (this.isLock && copy) {
        this.chatWindow.lock(false)
        this.isLock = false
      }
    },
    autoReply(key) {
      this.chatWindow.lock(true)
      this.isLock = true
      let self = this
      setTimeout(function () {
        self.chatGptReply('处理中，大概需要30s...', false) //每次发送消息后都要展示这个提示
      }, 300)
    },
    chatGptReply(value, isCopy = true) {
      this.chatWindow.talk(
        {
          robot: {
            says: [value],
            copy: isCopy,
            // reply: o.convo[o.standingAnswer].reply,
          },
        },
        'robot'
      )
    },
    initQuestion() {
      //第一次打开聊天框初始化的处理
      let self = this
      console.log(this.params)
      console.log('初始化聊天框时ws连接状态', this.websock.readyState)
      if (this.websock.readyState === 0) {
        //正在建立连接
        self.convo.robot.says[0] = this.connectText
        this.chatWindow.talk(self.convo)
        self.chatWindow.lock(true)
        this.isLock = true
      } else if (this.websock.readyState === 1) {
        //建立连接成功
        if (this.params.type == 'insight') {
          console.log('initQuestion')
          self.convo.robot.says[0] = this.insightText
          console.log(self.convo)
          this.chatWindow.talk(self.convo)
          let obj = Object.assign({}, this.params)
          let objs = _.omit(obj, ['type'])
          this.websocketsend(JSON.stringify(objs))
        } else if (this.params.type == 'write') {
          this.chatGptReply(this.writeText, false)
        }
      } else if (this.websock.readyState === 2) {
        //正在关闭连接
      } else if (this.websock.readyState === 3) {
        //连接已关闭
      }
    },
    //初始化Websocket
    initWebSocket() {
      console.log('initWebSocket')
      if (typeof WebSocket === 'undefined')
        return console.log('您的浏览器不支持websocket')
      this.websock = new WebSocket(this.wsUrl)
      this.websock.onmessage = this.websocketonmessage
      this.websock.onopen = this.websocketonopen
      this.websock.onerror = this.websocketonerror
      this.websock.onclose = this.websocketclose
    },
    websocketonopen() {
      console.log('WebSocket连接已经建立')
      this.isConnectWs = true
      if (this.isShow && this.isLock) {
        console.log(
          this.convo.robot.says[0],
          this.connectText,
          this.params.type
        )
        if (
          this.convo.robot.says[0] == this.connectText &&
          this.params.type == 'insight'
        ) {
          this.convo.robot.says[0] = this.insightText
          this.chatGptReply(this.insightText, false)
        }
        //提问后页面没收到答复，如果这时候断开了连接再次连接成功后需要把上次的问题再发送给后端
        console.log(this.params)
        let obj = Object.assign({}, this.params)
        let datas = _.omit(obj, ['type'])
        this.websocketsend(JSON.stringify(datas))
      }
      //   if (this.intervalId) {
      //     clearInterval(this.intervalId) //ws建立连接成功后清除重连定时器
      //   }
      this.counter = 0 //重置次数
      this.heartCheck() //建立连接后每30s发送一次心跳检测
    },
    websocketonmessage(e) {
      let self = this
      const redata = JSON.parse(e.data)
      console.log('接收ws返回的数据', e)
      if (redata.post_type == 'helper') {
        if (redata.data.length > 0) {
          this.defaultQuestionData = redata.data
          this.chatWindow.setDefaultQuestion(redata)
        }
      } else {
        self.chatGptReply(redata.answer)
      }
    },
    websocketonerror() {
      console.log('WebSocket连接发生错误')
    },
    websocketsend(Data) {
      let self = this
      //发送数据
      // console.log("数据发送", Data);
      //   setTimeout(function () {
      //     self.chatWindow.think()
      //   }, 1000)
      self.websock.send(Data)
    },
    websocketclose(e) {
      //关闭
      console.log('WebSocket连接已经关闭，执行重连', e)
      this.timeoutObj && clearInterval(this.timeoutObj)
      this.timeoutObj = null
      this.websock = null
      if (this.isConnectWs) {
        this.isConnectWs = false
        this.convo.robot.says[0] = this.connectText
        if (this.isShow) {
          this.chatWindow.talk(this.convo)
        }
      }
      this.reconnect()
    },
  },
}
</script>
<style scoped>
/* @import '../assets/style/setup.css';
@import '../assets/style/says.css';
@import '../assets/style/reply.css';
@import '../assets/style/typing.css';
@import '../assets/style/input.css'; */
</style>
<style scoped lang="less">
.dialogBox {
  position: fixed;
  right: 0;
  top: 60px;
  width: 380px;
  height: calc(100vh - 70px);
  min-width: 380px;
  z-index: 999999999;
  box-shadow: 0px 5px 22px 2px rgba(2, 2, 2, 0.15);
  border-radius: 15px 0px 0px 15px;
  /deep/ #chatRobotBox {
    height: calc(100% - 50px);
  }
  /deep/ .bubble-container {
    height: 100%;
    max-width: 100%;
    border-radius: 0px 0px 0px 15px;
  }
  /deep/ .bubble-container .input-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    color: #2c2c2c;
  }
  /deep/ .bubble-container .input-wrap textarea {
    margin: 0 24px 10px;
    resize: none;
    width: calc(100% - 48px);
    min-width: 80%;
    min-height: 40px;
    max-width: calc(100% - 48px);
    max-height: 100px;
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    color: #2c2c2c;
    background: #fff;
    font-size: 1em;
    letter-spacing: 0.5px;
    font-weight: 400;
    border: none;
    padding: 18px;
    outline: none;
    box-shadow: 0px 0px 5px 1px rgba(2, 2, 2, 0.08);
    border-radius: 10px;
    line-height: 1.25em;
    position: relative;
  }
  /deep/ .input-send {
    position: absolute;
    right: 44px;
    bottom: 26px;
    font-size: 20px;
    cursor: pointer;
    color: #6182f5;
  }
  /deep/ .hide {
    display: none;
  }
  .bubble-content {
    white-space: pre-line;
  }
  /deep/ .actionBox {
    margin-top: 10px;
    .copyBtn {
      display: inline-block;
      cursor: pointer;
      font-size: 12px;
      i {
        font-size: 16px;
      }
    }
  }
}
.head {
  height: 50px;
  background: #fff;
  border-radius: 15px 0px 0px 0px;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}
.tabs {
  width: 70%;
  .item {
    float: left;
    line-height: 30px;
    padding: 0 10px;
    cursor: pointer;
  }
  .noAllowed {
    cursor: not-allowed;
  }
  .noClick {
    pointer-events: none;
  }
  .b_line {
    position: relative;
    font-weight: 600;
  }
  .b_line::after {
    position: absolute;
    bottom: -2px;
    left: 50%;
    transform: translate(-50%, 0);
    content: '';
    width: 20px;
    height: 3px;
    display: block;
    background: #174ae4;
  }
}
.closeBtn {
  flex: 1;
  line-height: 30px;
  font-size: 28px;
  text-align: right;
  color: #737980;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
