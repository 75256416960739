/**
 * fenxi+ download the file through the link returned by API
 * @param {string} link
 */
function downloadFileByLink(link){
    return new Promise((resolve,reject) => {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = link
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href)
      document.body.removeChild(elink)
      resolve({done: true})
    })
}


export {
  downloadFileByLink
}
