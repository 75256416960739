const getters = {
  // token: (state) => state.user.token,
  // avatar: (state) => state.user.avatar,
  // name: (state) => state.user.name,
  // introduction: (state) => state.user.introduction,
  // roles: (state) => state.user.roles,
  selectedType: (state) => state.user.selectedType,
  selectedKeyWards: (state) => state.user.selectedKeyWards,
  plants: (state) => state.user.plants,
  startMonth: (state) => state.user.startMonth,
  endMonth: (state) => state.user.endMonth,
  catId: (state) => state.user.catId,
  ruleId: (state) => state.user.ruleId,
  routerRuleId: (state) => state.user.routerRuleId,
  analyseTyp: (state) => state.user.analyseTyp,
  routerAnalyseTyp: (state) => state.user.routerAnalyseTyp,
  words: (state) => state.user.words,
  labelLevel: (state) => state.user.labelLevel,
  aspectType: (state) => state.user.aspectType,
  lang: (state) => state.lang.lang,
  API_TOKEN: (state) => state.user.API_TOKEN,
  // logingState: (state) => state.user.logingState,
};
export default getters;
