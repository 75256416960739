import { parseTime, getObjFromUrl } from "@/utils";
export const timeRnage = {
  data() {
    return {
      org_start: this.$store.state.user.deadLineStartMonth,
      org_end: this.$store.state.user.deadLineEndMonth,
      pickerOptions_start: {
        disabledDate: (time) => {
          let org_start = this.org_start;
          let org_end = this.org_end;
          if (org_start != org_end) {
            //如果不加.replace(/-/g, "/") new Date出来的的日期是8点钟而不是0点
            if (time < new Date(org_start.replace(/-/g, "/"))) {
              return true;
            } else if (time > new Date(org_end.replace(/-/g, "/"))) {
              return true;
            } else {
              return false;
            }
          } else {
            if (
              time.getMonth() ==
              new Date(org_start.replace(/-/g, "/")).getMonth()
            ) {
              return false;
            } else {
              return true;
            }
          }
        },
      },
      pickerOptions_end: {
        disabledDate: (time) => {
          let org_start = this.org_start;
          let org_end = this.org_end;
          if (org_start != org_end) {
            if (time < new Date(this.$store.state.user.startMonth)) {
              return true;
            } else if (time > new Date(org_end)) {
              return true;
            } else {
              return false;
            }
          } else {
            if (time.getMonth() == new Date(org_start).getMonth()) {
              return false;
            } else {
              return true;
            }
          }
        },
      },
    };
  },
  methods: {},
  watch: {
    "$store.state.user.deadLineStartMonth": {
      handler(newVal, oldVal) {
        this.org_start = newVal;
      },
      deep: true,
    },
    "$store.state.user.deadLineEndMonth": {
      handler(newVal, oldVal) {
        this.org_end = newVal;
      },
      deep: true,
    },
  },
};

export const listenObj = {
  data() {
    return {};
  },
  computed: {
    listenObj() {
      return {
        plat: this.$store.state.user.plants,
        c_id: this.$store.state.user.cId,
        start: this.$store.state.user.startMonth,
        end: this.$store.state.user.endMonth,
        item_id: this.$store.state.user.itemId,
        sub_name: this.$store.state.user.subName,
        itemActivedTab: this.$store.state.user.itemActivedTab,
      };
    },
  },
  watch: {
    listenObj: {
      handler(newVal, oldVal) {
        if (
          JSON.stringify(newVal ? newVal : "") !==
            JSON.stringify(oldVal ? oldVal : "") &&
          this.$route.name == "skuDetail"
        ) {
          let param_val = getObjFromUrl(window.location.hash)
          let query = {
              top_id: this.$route.query.top_id,
              plat: this.$store.state.user.plants
                ? this.$store.state.user.plants
                : "",
              c_id: this.$store.state.user.cId
                ? this.$store.state.user.cId
                : "",
              start: parseTime(this.$store.state.user.startMonth, "{y}-{m}"),
              end: parseTime(this.$store.state.user.endMonth, "{y}-{m}"),
              item_id: this.$store.state.user.itemId,
              sub_name: this.$store.state.user.subName,
              itemActivedTab: this.$store.state.user.itemActivedTab,
              url_id: this.$route.query.url_id
            }
          if (param_val.commentUrlId) {
            query.commentUrlId = param_val.commentUrlId
          }
          this.$router.push({
            name: this.$route.name,
            query: query
          });
        }
      },
      deep: true,
    },
  },
};
