import i18n from "@/components/I18n";
// 图表label
const PIECHAR_TYPE = {
  posts: i18n.t("baseInfo.posts"), //"声量",
  interaction: i18n.t("baseInfo.interaction"),
  reposts: i18n.t("baseInfo.reposts"),
  comments: i18n.t("baseInfo.comments"),
  zans: i18n.t("baseInfo.zans"),
};

//平台类型
const PLANT_TYPE = {
  xhs: i18n.t("mainHead.platList_xhs"), //"声量",
  weibo: i18n.t("mainHead.platList_weibo"),
  douyin: i18n.t("mainHead.platList_douyin"),
  cshop: i18n.t("mainHead.cshop"),
  "cshop,tmall": i18n.t("mainHead['cshop,tmall']"),
  "cshop,tmall,jd": i18n.t("mainHead['cshop,tmall,jd']"),
  "cshop,tmall,douyin": i18n.t("mainHead['cshop,tmall,douyin']"),
  "cshop,jd": i18n.t("mainHead['cshop,jd']"),
  "tmall,jd": i18n.t("mainHead['tmall,jd']"),
  global: i18n.t("mainHead.global"),
  tmall: i18n.t("mainHead.tmall"),
  tmallhk: i18n.t("mainHead.tmallhk"),
  chaoshi: i18n.t("mainHead.chaoshi"),
  tmallhk_gfzy: i18n.t("mainHead.tmallhk_gfzy"),
  nochaoshi: i18n.t("mainHead.nochaoshi"),
  notmallhk: i18n.t("mainHead.notmallhk"),
  tmqjd: i18n.t("mainHead.tmqjd"),
  tmzyzmd: i18n.t("mainHead.tmzyzmd"),
  jd: i18n.t("mainHead.jd"),
  jd_only_self: i18n.t("mainHead.jd_only_self"),
  jd_exclude_self: i18n.t("mainHead.jd_exclude_self"),
  jd_hk: i18n.t("mainHead.jd_hk"),
  jd_home_self: i18n.t("mainHead.jd_home_self"),
  jd_home_pop: i18n.t("mainHead.jd_home_pop"),
  jd_not_home_self: i18n.t("mainHead.jd_not_home_self"),
  jd_not_home_pop: i18n.t("mainHead.jd_not_home_pop"),
  amazon_us: i18n.t("mainHead.amazon_us"),
  amazon_de: i18n.t("mainHead.amazon_de"),
  amazon_es: i18n.t("mainHead.amazon_es"),
  amazon_fr: i18n.t("mainHead.amazon_fr"),
  amazon_it: i18n.t("mainHead.amazon_it"),
  amazon_uk: i18n.t("mainHead.amazon_uk"),
  amazon_jp: i18n.t("mainHead.amazon_jp"),
  amazon_ae: i18n.t("mainHead.amazon_ae"),
  amazon_sa: i18n.t("mainHead.amazon_sa"),
  amazon_br: i18n.t("mainHead.amazon_br"),
  amazon_mx: i18n.t("mainHead.amazon_mx"),
  amazon: i18n.t("mainHead.amazon"),
  shopee: i18n.t("mainHead.shopee"),
  shopee_vn: i18n.t("mainHead.shopee_vn"),
  shopee_th: i18n.t("mainHead.shopee_th"),
  shopee_sg: i18n.t("mainHead.shopee_sg"),
  shopee_my: i18n.t("mainHead.shopee_my"),
  shopee_ph: i18n.t("mainHead.shopee_ph"),
  shopee_id: i18n.t("mainHead.shopee_id"),
  shopee_tw: i18n.t("mainHead.shopee_tw"),
  lazada: i18n.t("mainHead.lazada"),
  lazada_vn: i18n.t("mainHead.lazada_vn"),
  lazada_th: i18n.t("mainHead.lazada_th"),
  lazada_sg: i18n.t("mainHead.lazada_sg"),
  lazada_my: i18n.t("mainHead.lazada_my"),
  lazada_ph: i18n.t("mainHead.lazada_ph"),
  lazada_id: i18n.t("mainHead.lazada_id"),
};

// 原文链接地址
const POST_LINK = {
  xhs: "https://www.xiaohongshu.com/discovery/item/",
  weibo: "https://m.weibo.cn/status/",
  douyin: "https://www.douyin.com/video/",
};

export { PLANT_TYPE, PIECHAR_TYPE, POST_LINK };
