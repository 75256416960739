<template>
  <input class="range-input"
         :value="dateTime"
         :class="{disabled: disabled}"
         readonly>
</template>


<script>
export default {
  name: 'PickerInput',
  props: {
    dateTime: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
}
</script>

<style lang="less" >
.range-input {
  appearance: none;
  border: none;
  outline: none;
  display: inline-block;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #606266;
  background-color: transparent;
}
.disabled {
  cursor: not-allowed;
}
</style>
