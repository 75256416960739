<template>
  <div class="aside-tmall-cpn">
    <div class="cpn-header">
      <div class="img-div">
        <!-- <img src="./mmi_1111_icon.png"
             alt=""> -->
        <img src="./mmi_618_icon_new.png"
             alt="">
      </div>
      <div class="span-div">
        <span>{{$t('sideMenu.globalData')}}</span>
      </div>
    </div>
    <div class="cpn-links">
      <a href="https://market1.mktindex.com/shuang11new/#/topcat/618presale/2023"
         target="_blank">{{$t('sideMenu.preList')}}</a>
      <a href="https://market1.mktindex.com/shuang11new/#/topcat/618day/2022"
         target="_blank">{{$t('sideMenu.saleList')}}</a>
      <!-- <a href="https://market1.moojing.com/shuang11new/#/topcat/shuang11presale/2023"
         target="_blank">{{$t('sideMenu.preList')}}</a>
      <a href="https://market1.moojing.com/shuang11new/#/topcat/shuang11day/2023"
         target="_blank">{{$t('sideMenu.saleList')}}</a> -->
    </div>
  </div>
</template>


<script>
export default {
  // 侧边栏天猫大促模块
  name: 'AsideTmallCpn',
  data() {
    return {}
  },
}
</script>

<style lang="less" scoped>
.aside-tmall-cpn {
  border-top: 1px solid black;
  margin: 10px;
  padding: 10px 0;
  .cpn-header {
    margin-bottom: 10px;
    padding: 10px 5px 0 5px;
    display: flex;
    .span-div {
      display: flex;
      align-items: center;
    }
    img {
      width: 29px;
      vertical-align: bottom;
      margin-right: 8px;
    }
  }
  .cpn-links {
    display: flex;
    flex-direction: column;
    a {
      padding: 10px 16px;
      color: #303133;
      text-decoration: none;
    }
    a:hover {
      text-decoration: none;
      background-color: #f0f0f0;
    }
  }
}
</style>
