<template>
  <div class="concept-list-cpn"
       v-loading="loading">
    <div class="no-cat-message"
         v-if="!cid">
      {{ noCatMsg }}
    </div>
    <div class="word-list-area"
         v-else>
      <div v-if="wordList.length > 0"
           class="show-word-list-area">
        <div class="word-list"
             v-for="(item, index) in wordList"
             :key="item[0] + index">
          <span style="margin: 0 10px">{{ item[0] }}:</span>
          <span v-if="item[1].length <= 10">
            <el-button type="text"
                       v-for="(_item, _index) in item[1]"
                       :key="_item + _index"
                       @click="chooseItem(_item)">{{ _item }}</el-button>
            <el-button v-if="!$store.state.user.loginInfo.is_partner"
                       size="mini"
                       style="margin-left: 5px"
                       @click="jumpToChange">{{$t('search.view_more')}}</el-button>
          </span>
          <span v-else-if="item[1].length > 10 && !item[2]">
            <el-button type="text"
                       v-for="(_item, _index) in item[1].slice(0, 10)"
                       @click="chooseItem(_item)"
                       :key="_item + _index">{{ _item }}</el-button>
            <el-button size="mini"
                       style="margin-left: 5px"
                       @click="item.splice(2, 0, true)">{{$t('search.load_more')}}</el-button>
          </span>
          <span v-else-if="item[1].length > 10 && item[2]">
            <el-button type="text"
                       v-for="(_item, _index) in item[1]"
                       @click="chooseItem(_item)"
                       :key="_item + _index">{{ _item }}</el-button>
            <el-button v-if="!$store.state.user.loginInfo.is_partner"
                       size="mini"
                       style="margin-left: 5px"
                       @click="jumpToChange">{{$t('search.view_more')}}</el-button>
          </span>

          <!-- <el-button
            size="mini"
            v-if="index == wordList.length - 1"
            style="margin-left: 5px"
            @click="loadMore">加载更多</el-button> -->
        </div>
      </div>
      <div v-else
           class="no-cat-message">
        {{$t('search.not_find')}}
        <el-button sv-if="!$store.state.user.loginInfo.is_partner"
                   ize="mini"
                   style="margin-left: 5px"
                   @click="jumpToChange">{{$t('search.view_more')}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getWordLabel } from '@/api/public'
const NO_CAT_MESSAGE = '选择类目后自动推荐相关关键词'
export default {
  // 高增长概念推荐列表
  name: 'ConceptListCpn',
  props: {
    cats: {
      default: () => [],
      type: Array,
    },
    plat: {
      default: '',
      type: String,
    },
  },
  data() {
    return {
      noCatMsg: this.$t('search.word_placeholder'),
      limitNum: 30,

      loading: false,
      time: null,

      wordList: [],
    }
  },
  computed: {
    cid() {
      const cats = this.cats
      let cid
      if (cats.length == 0) {
        return ''
      }
      if (cats[0] && cats[0].custom) {
        cid = {
          custom: [cats[0]._id],
        }
      } else {
        const cids = cats.map((item) => item.id)
        cid = {
          not_custom: cids,
        }
      }
      return cid
    },
  },
  watch: {
    cid(newVal, oldVal) {
      if (newVal) {
        this.limit_num = 10
        this.getWordLabelDebounce()
      }
    },
    plat(newVal, oldVal) {
      if (newVal) {
        if (this.cid) {
          this.limit_num = 10
        }
        this.getWordLabelDebounce()
      }
    },
  },
  created() {
    this.getWordLabelDebounce = _.debounce(this.getWordLabelMethod, 300)
  },
  methods: {
    getCurrentTime() {
      return new Date().getTime()
    },
    getWordLabelMethod() {
      if (!this.cid) return

      // 给定最新请求时间戳,只显示最后的结果
      this.time = this.getCurrentTime()
      const timer = this.time
      const data = {
        plat: this.plat,
        cid: this.cid,
        limit_num: this.limitNum,
      }
      this.loading = true
      getWordLabel(data)
        .then((response) => {
          if (timer != this.time) return
          this.loading = false
          const result = response.result.data
          this.wordList = result
        })
        .catch((e) => {
          if (timer != this.time) return
          this.loading = false
          console.log(e)
        })
    },
    jumpToChange() {
      // 跳转到增长雷达
      if (window.location.href.indexOf('fenxiplus.mktindex.com') != -1) {
        window.open('https://chance.mktindex.com')
      } else {
        window.open('https://chancetest.mktindex.com')
      }
    },
    chooseItem(item) {
      this.$emit('chooseConcept', item)
    },
  },
}
</script>

<style lang="less" scoped>
.concept-list-cpn {
  min-width: 350px;
  max-width: 500px;
  height: 120px;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  overflow: auto;

  .no-cat-message {
    margin-left: 10px;
    color: gray;
  }
  .word-list-area {
    .load-more {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 5px;
      margin-right: 5px;
    }
    .el-button {
      padding: 5px 10px;
    }

    .el-button--mini:focus {
      background: #ffffff;
      border: 1px solid #dcdfe6;
      border-color: #dcdfe6;
      color: #606266;
    }
  }
}
</style>
