<template>
  <div>
    <el-dialog class="generate-ppt-comp"
               :append-to-body="true"
               :show-close="false"
               :visible.sync="dialogVisible"
               :close-on-press-escape="false"
               :close-on-click-modal="false"
               width="30%">
      <div class="text-content">
        <i class="el-icon-loading"
           style=""></i>
        <span style="font-size: 18px">生成中...</span>
      </div>

      <span slot="footer"
            class="dialog-footer">
        <el-button @click="dialogVisible = false"
                   size="mini">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>
import { getGenPPT } from '@/api/public'
import { downloadFileByLink } from '@/utils/download'
export default {
  name: 'GeneratePPT',
  data() {
    return {
      dialogVisible: false,
    }
  },
  props: {},
  computed: {},
  methods: {
    generate() {
      this.dialogVisible = true
      const params = {
        start: this.$route.query.start,
        end: this.$route.query.end,
        url_id: this.$route.query.url_id,
        jd_version: JSON.parse(localStorage.getItem('jdVersion')),
      }
      if (this.$store.state.user.version) {
        params.version = this.$store.state.user.version
      }
      getGenPPT(params)
        .then((res) => {
          if (!this.dialogVisible) return
          if (res.status == 'ok') {
            const result = res.result
            if (result.link) {
              downloadFileByLink(result.link).then((res) => {})
            } else {
              this.$message.error('下载失败! 缺少文件路径, 请截屏并联系客服')
            }
            this.dialogVisible = false
          }
        })
        .catch((error) => {
          if (!this.dialogVisible) return
          if (error.backMsg) {
            this.$message.error(error.backMsg)
          } else {
            this.$message.error('服务器错误，请截屏并联系客服')
          }
          this.dialogVisible = false
        })
    },
  },
  created() {},
}
</script>

<style lang="less">
.generate-ppt-comp {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 10px 20px;
    padding-top: 30px;
  }
  .el-dialog__footer {
    padding: 10px;
  }
  .text-content {
    color: #3a63f3;
    .el-icon-loading {
      margin-right: 10px;
    }
  }
}
</style>
