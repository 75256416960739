
export const themes = {
  default: {
    fenxiPrimaryColor: '#3A63F3',
    fenxiPrimaryTextColor: '#fff',
    fenxiPrimaryOpacityColor: '#3A63F320',
    fenxiTextColor: '#333',
    fenxiBackgroundColor: '#fff',
    fenxiTopHeaderColor: 'rgb(6, 10, 50)',
    fenxiTopHeaderTextColor: '#fff'
  },
  orange: {
    fenxiPrimaryColor: '#ff7d00',
    fenxiPrimaryTextColor: '#fff',
    fenxiPrimaryOpacityColor: '#ff7d0020',
    fenxiTextColor: '#333',
    fenxiBackgroundColor: '#fff',
    fenxiTopHeaderColor: '#fff',
    fenxiTopHeaderTextColor: '#ff7d00'
  }
}

const changeStyle = (obj) => {
  for(let key in obj){
    document.documentElement.style.setProperty(`--${key}`,obj[key])
  }
}

export const setTheme = (themeName) => {
  const themeConfig = themes[themeName];
  if(themeConfig){
    changeStyle(themeConfig)
  }else{
    changeStyle(themeConfig)
  }
}
