<template>
  <div>
    <!-- 加平台需修改 -->
    <div v-if="!isOversea"
         class="fenxi-platform-selector">
      <el-checkbox-group v-model="checkedPlats"
                         class="platform-check-boxes">
        <div class="platform-check-boxes-item">
          <el-checkbox type="checkbox"
                       label="taobao"
                       :disabled="allDisabled"
                       style="cursor: pointer; height: 100%; margin: 0">
          </el-checkbox>
          <el-select v-model="tbValue"
                     @change="selectChange('taobao')"
                     :disabled="allDisabled"
                     :class="$i18n.locale == 'en' ? 'en-font' : ''">
            <el-option v-for="item in tbOptions"
                       :key="item.value"
                       :label="item.label"
                       :class="$i18n.locale == 'en' ? 'en-font' : ''"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="platform-check-boxes-item">
          <el-checkbox type="checkbox"
                       label="tmall"
                       :disabled="allDisabled"
                       style="cursor: pointer; height: 100%; margin: 0px;">
          </el-checkbox>
          <el-select v-model="tmallValue"
                     @change="selectChange('tmall')"
                     :disabled="allDisabled"
                     :class="$i18n.locale == 'en' ? 'en-font' : ''">
            <el-option v-for="item in tmallOptions"
                       :class="$i18n.locale == 'en' ? 'en-font' : ''"
                       :key="item.value"
                       :label="item.label"
                       :disabled="allDisabled"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="platform-check-boxes-item">
          <el-checkbox type="checkbox"
                       label="jd"
                       :disabled="allDisabled"
                       style="cursor: pointer; height: 100%; margin: 0">
          </el-checkbox>
          <el-select v-model="jdValue"
                     @change="selectChange('jd')"
                     :disabled="allDisabled"
                     :class="$i18n.locale == 'en' ? 'en-font' : ''">
            <el-option v-for="item in jdOptions"
                       :class="$i18n.locale == 'en' ? 'en-font' : ''"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="platform-check-boxes-item">
          <el-checkbox type="checkbox"
                       label="douyin"
                       :disabled="allDisabled"
                       style="cursor: pointer; height: 100%; margin: 0">
          </el-checkbox>
          <el-select v-model="douyinValue"
                     @change="selectChange('douyin')"
                     :disabled="allDisabled"
                     :class="$i18n.locale == 'en' ? 'en-font' : ''">
            <el-option v-for="item in douyinOptions"
                       :class="$i18n.locale == 'en' ? 'en-font' : ''"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
      </el-checkbox-group>
    </div>

    <div v-if="isOversea"
         class="oversea-platform-selector">
      <el-checkbox-group v-model="checkedPlats"
                         class="platform-check-boxes">
        <div class="platform-check-boxes-item">
          <el-checkbox type="checkbox"
                       label="amazon"
                       style="cursor: pointer; height: 100%; margin: 0">
          </el-checkbox>
          <el-select v-model="amazonValue"
                     @change="selectChange('amazon')"
                     :class="$i18n.locale == 'en' ? 'en-font' : ''">
            <el-option v-for="item in amazonOptions"
                       :class="$i18n.locale == 'en' ? 'en-font' : ''"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="platform-check-boxes-item">
          <el-checkbox type="checkbox"
                       label="shopee"
                       style="cursor: pointer; height: 100%; margin: 0">
          </el-checkbox>
          <el-select v-model="shopeeValue"
                     :class="$i18n.locale == 'en' ? 'en-font' : ''"
                     @change="selectChange('shopee')">
            <el-option v-for="item in shopeeOptions"
                       :class="$i18n.locale == 'en' ? 'en-font' : ''"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="platform-check-boxes-item">
          <el-checkbox type="checkbox"
                       label="lazada"
                       style="cursor: pointer; height: 100%; margin: 0">
          </el-checkbox>
          <el-select v-model="lazadaValue"
                     @change="selectChange('lazada')"
                     :class="$i18n.locale == 'en' ? 'en-font' : ''">
            <el-option v-for="item in lazadaOptions"
                       :class="$i18n.locale == 'en' ? 'en-font' : ''"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="platform-check-boxes-item">
          <el-checkbox type="checkbox"
                       label="tiktok"
                       style="cursor: pointer; height: 100%; margin: 0">
          </el-checkbox>
          <el-select v-model="tiktokValue"
                     @change="selectChange('tiktok')"
                     :class="$i18n.locale == 'en' ? 'en-font' : ''">
            <el-option v-for="item in tiktokOptions"
                       :class="$i18n.locale == 'en' ? 'en-font' : ''"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
        <div class="platform-check-boxes-item">
          <el-checkbox type="checkbox"
                       label="ozon"
                       style="cursor: pointer; height: 100%; margin: 0">
          </el-checkbox>
          <el-select v-model="ozonValue"
                     @change="selectChange('ozon')"
                     :class="$i18n.locale == 'en' ? 'en-font' : ''">
            <el-option v-for="item in ozonOptions"
                       :class="$i18n.locale == 'en' ? 'en-font' : ''"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </div>
      </el-checkbox-group>
    </div>
  </div>
</template>


<script>
import i18n from '@/components/I18n'
// import intro from 'intro.js'
// import 'intro.js/introjs.css'
// import 'intro.js/themes/introjs-modern.css'
export default {
  name: 'Platform',
  props: {
    defaultPlat: {
      type: String,
      default: '',
    },
    allDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    tbLabel() {
      for (let i = 0; i < this.tbOptions.length; i++) {
        const item = this.tbOptions[i]
        if (item.value == this.tbValue) {
          return item.label
        }
      }
    },
    tmallLabel() {
      for (let i = 0; i < this.tmallOptions.length; i++) {
        const item = this.tmallOptions[i]
        if (item.value == this.tmallValue) {
          return item.label
        }
      }
    },
    jdLabel() {
      for (let i = 0; i < this.jdOptions.length; i++) {
        const item = this.jdOptions[i]
        if (item.value == this.jdValue) {
          return item.label
        }
      }
    },
    // amazon
    // 加平台需修改
    amazonLabel() {
      for (let i = 0; i < this.amazonOptions.length; i++) {
        const item = this.amazonOptions[i]
        if (item.value == this.amazonValue) {
          return item.label
        }
      }
    },
    // douyin
    douyinLabel() {
      for (let i = 0; i < this.douyinOptions.length; i++) {
        const item = this.douyinOptions[i]
        if (item.value == this.douyinValue) {
          return item.label
        }
      }
    },

    shopeeLabel() {
      for (let i = 0; i < this.shopeeOptions.length; i++) {
        const item = this.shopeeOptions[i]
        if (item.value == this.shopeeValue) {
          return item.label
        }
      }
    },
    lazadaLabel() {
      for (let i = 0; i < this.lazadaOptions.length; i++) {
        const item = this.lazadaOptions[i]
        if (item.value == this.lazadaValue) {
          return item.label
        }
      }
    },
  },
  created() {
    if (this.defaultPlat.length > 0) {
      const defaultPlats = this.defaultPlat.split(',')
      this.checkedPlats = defaultPlats.map((item) => {
        const plat = this.checkPlatFenxi(item)
        if (plat == 'taobao') {
          this.tbValue = item == 'taobao' ? 'cshop' : item
        } else if (plat == 'tmall') {
          this.tmallValue = item
        } else if (plat == 'jd') {
          this.jdValue = item
        } else if (plat == 'amazon') {
          this.amazonValue = item
        } else if (plat == 'shopee') {
          this.shopeeValue = item
        } else if (plat == 'lazada') {
          this.lazadaValue = item
        } else if (plat == 'tiktok') {
          this.tiktokValue = item
        } else if (plat == 'ozon') {
          this.ozonValue = item
        }
        return plat
      })
    } else {
      this.checkedPlats = this.isOversea ? ['amazon'] : ['taobao', 'tmall']
    }
  },
  data() {
    return {
      isOversea: this.$store.state.user.isOversea,
      checkedPlats: [],
      tbOptions: [
        {
          label: this.$t('plat.taobao'),
          value: 'cshop',
        },
        {
          label: this.$t('plat.global'),
          value: 'global',
        },
      ],
      tbValue: 'cshop',
      tmallValue: 'tmall',
      tmallOptions: [
        {
          label: this.$t('plat.tmall'),
          value: 'tmall',
        },
        {
          label: this.$t('plat.tmallhk'),
          value: 'tmallhk',
        },
        {
          label: this.$t('plat.chaoshi'),
          value: 'chaoshi',
        },
        {
          label: this.$t('plat.tmallhk_gfzy'),
          value: 'tmallhk_gfzy',
        },
        {
          label: this.$t('plat.nochaoshi'),
          value: 'nochaoshi',
        },
        {
          label: this.$t('plat.notmallhk'),
          value: 'notmallhk',
        },
        {
          label: this.$t('plat.tmqjd'),
          value: 'tmqjd',
        },
        {
          label: this.$t('plat.tmzyzmd'),
          value: 'tmzyzmd',
        },
      ],
      jdValue: 'jd',
      jdOptions: [
        {
          label: this.$t('plat.jd'),
          value: 'jd',
        },
        {
          label: this.$t('plat.jd_only_self'),
          value: 'jd_only_self',
        },
        {
          label: this.$t('plat.jd_exclude_self'),
          value: 'jd_exclude_self',
        },
        {
          label: this.$t('plat.jd_hk'),
          value: 'jd_hk',
        },
        {
          label: this.$t('plat.jd_home_self'),
          value: 'jd_home_self',
        },
        {
          label: this.$t('plat.jd_home_pop'),
          value: 'jd_home_pop',
        },
        {
          label: this.$t('plat.jd_not_home_self'),
          value: 'jd_not_home_self',
        },
        {
          label: this.$t('plat.jd_not_home_pop'),
          value: 'jd_not_home_pop',
        },
      ],
      // 加平台需修改
      amazonValue: 'amazon_us',
      amazonOptions: [
        {
          label: i18n.t('mainHead.amazon_us'),
          value: 'amazon_us',
        },
        {
          label: i18n.t('mainHead.amazon_de'),
          value: 'amazon_de',
        },
        {
          label: i18n.t('mainHead.amazon_es'),
          value: 'amazon_es',
        },
        {
          label: i18n.t('mainHead.amazon_fr'),
          value: 'amazon_fr',
        },
        {
          label: i18n.t('mainHead.amazon_it'),
          value: 'amazon_it',
        },
        {
          label: i18n.t('mainHead.amazon_uk'),
          value: 'amazon_uk',
        },
        {
          label: i18n.t('mainHead.amazon_jp'),
          value: 'amazon_jp',
        },
        {
          label: i18n.t('mainHead.amazon_ae'),
          value: 'amazon_ae',
        },
        {
          label: i18n.t('mainHead.amazon_sa'),
          value: 'amazon_sa',
        },
        {
          label: i18n.t('mainHead.amazon_br'),
          value: 'amazon_br',
        },
        {
          label: i18n.t('mainHead.amazon_mx'),
          value: 'amazon_mx',
        },
      ],
      shopeeValue: 'shopee_id',
      shopeeOptions: [
        {
          value: 'shopee_id',
          label: this.$t('plat.shopee_id'),
        },
        {
          value: 'shopee_my',
          label: this.$t('plat.shopee_my'),
        },
        {
          value: 'shopee_ph',
          label: this.$t('plat.shopee_ph'),
        },
        {
          value: 'shopee_sg',
          label: this.$t('plat.shopee_sg'),
        },
        {
          value: 'shopee_th',
          label: this.$t('plat.shopee_th'),
        },
        {
          value: 'shopee_tw',
          label: this.$t('plat.shopee_tw'),
        },
        {
          value: 'shopee_vn',
          label: this.$t('plat.shopee_vn'),
        },
      ],
      lazadaValue: 'lazada_id',
      lazadaOptions: [
        {
          value: 'lazada_my',
          label: this.$t('plat.lazada_my'),
        },
        {
          value: 'lazada_sg',
          label: this.$t('plat.lazada_sg'),
        },
        {
          value: 'lazada_th',
          label: this.$t('plat.lazada_th'),
        },
        {
          value: 'lazada_ph',
          label: this.$t('plat.lazada_ph'),
        },
        {
          value: 'lazada_id',
          label: this.$t('plat.lazada_id'),
        },
        {
          value: 'lazada_vn',
          label: this.$t('plat.lazada_vn'),
        },
      ],
      tiktokValue: 'tiktok_gb',
      tiktokOptions: [
        {
          value: 'tiktok_gb',
          label: this.$t('plat.tiktok_gb'),
        },
        {
          value: 'tiktok_id',
          label: this.$t('plat.tiktok_id'),
        },
        {
          value: 'tiktok_th',
          label: this.$t('plat.tiktok_th'),
        },
        {
          value: 'tiktok_my',
          label: this.$t('plat.tiktok_my'),
        },
        {
          value: 'tiktok_ph',
          label: this.$t('plat.tiktok_ph'),
        },
        {
          value: 'tiktok_sg',
          label: this.$t('plat.tiktok_sg'),
        },
        {
          value: 'tiktok_us',
          label: this.$t('plat.tiktok_us'),
        },
        {
          value: 'tiktok_vn',
          label: this.$t('plat.tiktok_vn'),
        },
      ],
      ozonValue: 'ozon_ru',
      ozonOptions: [
        {
          value: 'ozon_ru',
          label: 'ozon',
        },
      ],
      // douyin
      douyinValue: 'douyin',
      douyinOptions: [
        {
          label: this.$t('plat.douyin'),
          value: 'douyin',
        },
      ],
      selectedPlats: [],
    }
  },
  watch: {
    checkedPlats(newVal) {
      this.changeSelectedPlats()
    },
    // 加平台需修改
    selectedPlats(newVal) {
      //多平台打通功能跨平台就展示淘系，单平台
      const length = newVal.length
      const valStr = newVal.join()
      if (length == 1) {
        if (valStr == 'cshop' || valStr == 'tmall') {
          this.$emit('showPlats', 3)
        } else if (valStr == 'jd') {
          this.$emit('showPlats', 4)
        } else if (valStr.indexOf('jd') == 0) {
          // 京东子平台
          this.$emit('showPlats', 2)
        } else if (
          valStr.indexOf('amazon') > -1 ||
          valStr.indexOf('lazada') > -1 ||
          valStr.indexOf('shopee') > -1 ||
          valStr.indexOf('ozon') > -1 ||
          valStr.indexOf('tiktok') > -1
        ) {
          if (
            valStr == 'amazon_us' ||
            valStr == 'amazon_de' ||
            valStr == 'amazon_es' ||
            valStr == 'amazon_fr' ||
            valStr == 'amazon_it' ||
            valStr == 'amazon_uk' ||
            valStr == 'amazon_jp' ||
            valStr == 'amazon_ae' ||
            valStr == 'amazon_sa' ||
            valStr == 'amazon_br' ||
            valStr == 'amazon_mx' ||
            valStr == 'lazada_my' ||
            valStr == 'lazada_sg' ||
            valStr == 'lazada_th' ||
            valStr == 'lazada_ph' ||
            valStr == 'lazada_id' ||
            valStr == 'lazada_vn' ||
            valStr == 'shopee_my' ||
            valStr == 'shopee_sg' ||
            valStr == 'shopee_th' ||
            valStr == 'shopee_ph' ||
            valStr == 'shopee_id' ||
            valStr == 'shopee_vn' ||
            valStr == 'shopee_tw' ||
            valStr == 'tiktok_gb' ||
            valStr == 'tiktok_id' ||
            valStr == 'tiktok_my' ||
            valStr == 'tiktok_ph' ||
            valStr == 'tiktok_sg' ||
            valStr == 'tiktok_th' ||
            valStr == 'tiktok_us' ||
            valStr == 'tiktok_vn' ||
            valStr == 'ozon_ru'
          ) {
            this.$emit('showPlats', valStr)
          } else {
            this.$emit('showPlats', 6)
          }
        } else if (valStr == 'douyin') {
          // 抖音
          this.$emit('showPlats', 8)
        } else {
          // 淘宝子平台
          this.$emit('showPlats', 1)
        }
      } else if (length == 2) {
        if (this.isOversea) {
          this.$emit('showPlats', 6)
        } else {
          if (valStr == 'cshop,tmall' || valStr == 'tmall,cshop') {
            this.$emit('showPlats', 3)
          } else if (valStr == 'jd,tmall' || valStr == 'tmall,jd') {
            this.$emit('showPlats', 11)
          } else if (valStr == 'jd,cshop' || valStr == 'cshop,jd') {
            this.$emit('showPlats', 11)
          } else {
            this.$emit('showPlats', 1)
          }
        }
      } else if (length == 3) {
        if (this.isOversea) {
          this.showCats = 6
        } else {
          if (
            valStr.indexOf('cshop') > -1 &&
            valStr.indexOf('tmall') > -1 &&
            valStr.indexOf('jd') > -1
          ) {
            this.$emit('showPlats', 11)
          } else {
            this.$emit('showPlats', 1)
          }
        }
      } else if (length == 4) {
        if (this.isOversea) {
          this.$emit('showPlats', 6)
        } else {
          this.$emit('showPlats', 1)
        }
      } else {
        if (this.isOversea) {
          this.$emit('showPlats', 6)
        }
      }
      this.$emit('selectedPlats', newVal)
    },
    defaultPlat() {
      if (this.defaultPlat.length > 0) {
        const defaultPlats = this.defaultPlat.split(',')
        this.checkedPlats = defaultPlats.map((item) => {
          const plat = this.checkPlatFenxi(item)
          if (plat == 'taobao') {
            this.tbValue = item == 'taobao' ? 'cshop' : item
          } else if (plat == 'tmall') {
            this.tmallValue = item
          } else if (plat == 'jd') {
            this.jdValue = item
          } else if (plat == 'amazon') {
            this.amazonValue = item
          } else if (plat == 'shopee') {
            this.shopeeValue = item
          } else if (plat == 'lazada') {
            this.lazadaValue = item
          }
          return plat
        })
      }
    },
  },
  methods: {
    selectChange(plat) {
      if (this.checkedPlats.indexOf(plat) > -1) {
        this.changeSelectedPlats()
      }
    },
    // 加平台需修改
    changeSelectedPlats() {
      this.selectedPlats = this.checkedPlats.map((item) => {
        if (item == 'taobao') {
          return this.tbValue
        } else if (item == 'tmall') {
          return this.tmallValue
        } else if (item == 'jd') {
          return this.jdValue
        } else if (item == 'amazon') {
          return this.amazonValue
        } else if (item == 'shopee') {
          return this.shopeeValue
        } else if (item == 'lazada') {
          return this.lazadaValue
        } else if (item == 'douyin') {
          return this.douyinValue
        } else if (item == 'tiktok') {
          return this.tiktokValue
        } else if (item == 'ozon') {
          return this.ozonValue
        } else {
          return item
        }
      })
    },
    // 加平台需修改
    checkPlatFenxi(plat) {
      switch (plat) {
        case 'global':
        case 'cshop':
          return 'taobao'
        case 'tmall':
        case 'chaoshi':
        case 'nochaoshi':
        case 'tmallhk':
        case 'notmallhk':
        case 'tmzyzmd':
        case 'tmqjd':
        case 'tmallhk_gfzy':
        case 'all':
          return 'tmall'

        case 'jd':
        case 'jd_only_self':
        case 'jd_exclude_self':
        case 'jd_hk':
        case 'jd_home_self':
        case 'jd_home_pop':
        case 'jd_not_home_self':
        case 'jd_not_home_pop':
          return 'jd'
        case 'amazon_us':
        case 'amazon_de':
        case 'amazon_es':
        case 'amazon_fr':
        case 'amazon_it':
        case 'amazon_uk':
        case 'amazon_jp':
        case 'amazon_ae':
        case 'amazon_sa':
        case 'amazon_br':
        case 'amazon_mx':
          return 'amazon'
        case 'shopee_br':
        case 'shopee_cl':
        case 'shopee_co':
        case 'shopee_es':
        case 'shopee_fr':
        case 'shopee_id':
        case 'shopee_in':
        case 'shopee_mx':
        case 'shopee_my':
        case 'shopee_ph':
        case 'shopee_pl':
        case 'shopee_sg':
        case 'shopee_th':
        case 'shopee_tw':
        case 'shopee_vn':
          return 'shopee'
        case 'lazada_my':
        case 'lazada_sg':
        case 'lazada_th':
        case 'lazada_ph':
        case 'lazada_id':
        case 'lazada_vn':
          return 'lazada'
        case 'tiktok_gb':
        case 'tiktok_id':
        case 'tiktok_my':
        case 'tiktok_ph':
        case 'tiktok_sg':
        case 'tiktok_th':
        case 'tiktok_us':
        case 'tiktok_vn':
          return 'tiktok'
        case 'ozon_ru':
          return 'ozon'
        default:
          return plat
      }
    },
  },
  mounted() {},
}
</script>

<style lang="less" >
.fenxi-platform-selector {
  display: flex;
  .platform-label {
    flex-basis: 80px;
  }
  .platform-check-boxes {
    flex-basis: 500px;
    display: flex;
    justify-content: space-around;
    .el-checkbox__label {
      padding: 0;
      margin-right: 10px;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: @fenxiPrimaryColor !important;
      border-color: @fenxiPrimaryColor !important;
    }
    .platform-check-boxes-item {
      flex: 1;
      display: flex;
      margin-right: 10px;
      .el-checkbox__label {
        display: none;
      }
    }
    .el-select {
      margin-left: 5px;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0 10px 0 10px;
      // margin-left: 5px;
    }
    .el-select .el-input.is-focus .el-input__inner {
      border-color: #dcdfe6;
      border-radius: 2px;
    }
    .el-input__suffix {
      right: -5px;
      .el-input__icon {
        line-height: 100%;
      }
    }
    .el-select .el-input .el-select__caret {
      color: black;
    }
  }
}
.oversea-platform-selector {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 创建三个等宽的列 */
  gap: 10px; /* 列与列之间的间隔 */
  width: 100%; /* 容器宽度为100%，适应其父元素 */
  .platform-label {
    min-width: 80px;
  }
  .platform-check-boxes {
    min-width: 480px;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 创建三个等宽的列 */
    gap: 10px; /* 列与列之间的间隔 */
    width: 100%; /* 容器宽度为100%，适应其父元素 */
    .el-checkbox__label {
      padding: 0;
      margin-right: 10px;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: @fenxiPrimaryColor !important;
      border-color: @fenxiPrimaryColor !important;
    }
    .platform-check-boxes-item {
      flex: 1;
      display: flex;
      margin-right: 10px;
      .el-checkbox__label {
        display: none;
      }
    }
    .el-select {
      margin-left: 5px;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0 10px 0 10px;
      // margin-left: 5px;
    }
    .el-select .el-input.is-focus .el-input__inner {
      border-color: #dcdfe6;
      border-radius: 2px;
    }
    .el-input__suffix {
      right: -5px;
      .el-input__icon {
        line-height: 100%;
      }
    }
    .el-select .el-input .el-select__caret {
      color: black;
    }
  }
}
// .introjs-tooltip {
//   background-color: #ffffff;
//   color: #333333;
//   .introjs-button {
//     color: #333333;
//     border: 1px solid #333333;
//     border-radius: 5px;
//   }
//   .introjs-prevbutton {
//     display: none;
//   }
//   .introjs-nextbutton {
//     color: #ffffff;
//     background-color: blue;
//     border: 0px;
//   }
//   .introjs-skipbutton {
//     font-size: 17px;
//   }
//   .introjs-bullets {
//     display: none;
//   }
// }
</style>
