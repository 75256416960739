<template>
  <div class="back-cmi">
    <a :href="cmiUrl"
       class="back-a">
      <div class="back-a-content">
        <img src="./img/back_cmi.png"
             alt=""
             style='width: 30px;height: 30px'>
        <span :class="$i18n.locale == 'en' ? 'en-font' : ''">{{this.$t('sideMenu.back_cmi')}}</span>
      </div>
    </a>
  </div>
</template>


<script>
const cmiUrl = 'https://cmi.mktindex.com/#/app'
const cmiTestUrl = 'https://cmitest.mktindex.com/#/app'
export default {
  name: 'BackCmi',
  data() {
    return {
      cmiUrl: cmiUrl,
    }
  },
  porps: {},
  computed: {},
  methods: {},
  created() {},
}
</script>

<style lang="less" scoped>
.back-cmi {
  .back-a {
    display: block;
    cursor: pointer;
    .back-a-content {
      display: flex;
      justify-content: center;
      span {
        color: #d01b28;
        line-height: 30px;
        margin-left: 10px;
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      }
    }
  }
  .back-a:hover {
    text-decoration: none;
  }
}
</style>
