import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import qs from "qs";
import "@/style/index.less";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
import "font-awesome/css/font-awesome.min.css";
import "element-ui/lib/theme-chalk/index.css";
// import "@/style/element-variables.scss";
import "@/assets/iconfont/iconfont.css";
import "@/assets/iconfont/iconfont.js";
import ElementUI from "element-ui";
// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueECharts from "vue-echarts/components/ECharts.vue";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/component/polar";
import "echarts/lib/chart/scatter";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import $ from "jquery";
import "bootstrap/dist/js/bootstrap.min";
import Datatable from "vue2-datatable-component";
import locale_zh from "vue2-datatable-component/locale/zh-cn";
//import locale_default from "vue2-datatable-component/locale/pt-br";
import _ from "lodash";
import less from "less";
import i18n from "./components/I18n";
import "./directive";
import VueWorker from "vue-worker";
import UmyUi from 'umy-ui'
import 'umy-ui/lib/theme-chalk/index.css';// 引入样式
import { setTheme } from '@/style/theme-model'
import ErrorStackParser from 'error-stack-parser';
import {clientError} from '@/api/public'
import { getBaseErrorObj } from '@/utils/index.js'
Vue.use(VueWorker);
Vue.use(less);
Vue.use(UmyUi);
Vue.prototype._ = _;
Vue.prototype.$qs = qs;
Vue.prototype.$eventBus = new Vue();
Vue.config.productionTip = false;
Vue.use(ElementUI, { i18n: (key, value) => i18n.t(key, value) });
Vue.prototype.$axios = axios;
// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);
let local_datatable = {};
local_datatable.locale = locale_zh;
const theme = localStorage.getItem('theme')
if(theme == 'orange'){
  import('@/style/element-variables-orange.scss')
}else if(theme == 'default'){
  import('@/style/element-variables.scss')
}else{
  import('@/style/element-variables.scss')
}
Vue.use(Datatable, local_datatable); //{ locale: locale_zh });
Vue.component("v-chart", VueECharts);
Vue.config.errorHandler = function (err, vm, info) {
    console.log('Error: ', err, 'Vue Instance: ', vm, 'Info: ', info);
    const parsedError = ErrorStackParser.parse(err);
    console.log(err.name, err.message, parsedError)
    const baseObj = getBaseErrorObj()
    baseObj.error_msg = err.message
    baseObj.stackTrace = parsedError
    clientError(baseObj)
    // Send error information to a logging service
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
