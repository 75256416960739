import request from "@/utils/request";
import { formateObjToParamStr } from "@/utils";
// import { encryptByDES } from '@/utils/crypto'
import qs from "qs";
import axios from "axios";
const BASE_API = process.env.VUE_APP_BASE_API;
const REAL_BASE_API =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_REAL_BASE_API
    : process.env.VUE_APP_BASE_API;
// 统计
export function getDefaultDate(param) {
  return request({
    url: `${BASE_API}/api/latesttime`,
    method: "get",
    params: param,
  });
}

export function getItemInfo(param) {
  //商品详情页的获取基本信息
  return request({
    url: `${BASE_API}/api/sub/item_info`,
    method: "get",
    params: param,
  });
}
export function getItemSalesTrend(param) {
  //商品详情页的获取销售趋势图表
  return request({
    url: `${BASE_API}/api/sub/item_sales_trend`,
    method: "get",
    params: param,
  });
}
export function getItemSalesTrendUrl(data) {
  //商品详情页的获取销售趋势图表下载
  return request({
    url: `${BASE_API}/api/sub/item_sales_trend`,
    method: "post",
    data: data,
  });
}
export function getItemAttr(param) {
  //商品详情页的属性数据
  return request({
    url: `${BASE_API}/api/sub/item_attr`,
    method: "get",
    params: param,
  });
}
export function getItemSkuSales(param) {
  //商品详情页的sku销量列表接口
  return request({
    url: `${BASE_API}/api/sub/item_sku_sales`,
    method: "get",
    params: param,
  });
}
export function getItemSkuSalesTrend(param) {
  //商品详情页的sku销量销售趋势图表
  return request({
    url: `${BASE_API}/api/sub/item_sku_sales_trend`,
    method: "get",
    params: param,
  });
}
export function getItemSkuSalesTrendUrl(data) {
  //商品详情页的sku销量销售趋势图表下载
  return request({
    url: `${BASE_API}/api/sub/item_sku_sales_trend`,
    method: "post",
    data: data,
  });
}
export function getItemSkuAttrKeys(param) {
  //商品详情页的sku属性分析销售趋势图表
  return request({
    url: `${BASE_API}/api/sub/item_sku_attr_keys`,
    method: "get",
    params: param,
  });
}
export function getItemSkuAttrSalesTrend(param) {
  //商品详情页的sku属性分析销售趋势图表
  return request({
    url: `${BASE_API}/api/sub/item_sku_attr_sales_trend`,
    method: "get",
    params: param,
  });
}
export function getItemSkuAttrSales(param) {
  //商品详情页的sku属性分析销售趋势图表
  return request({
    url: `${BASE_API}/api/sub/item_sku_attr_sales`,
    method: "get",
    params: param,
  });
}
export function getItemSkuAttrSalesTrendUrl(data) {
  //商品详情页的sku属性分析销售趋势图表下载
  return request({
    url: `${BASE_API}/api/sub/item_sku_attr_sales_trend`,
    method: "post",
    data: data,
  });
}
export function getAttrCrossAnalyze(param) {
  return request({
    url: `${BASE_API}/api/sub/attr_cross_analyze`,
    method: "get",
    params: param,
  });
}
//属性交叉分析下载地址
export function getAttrCrossAnalyzeUrl(data) {
  return request({
    url: `${BASE_API}/api/sub/attr_cross_analyze`,
    method: "post",
    data: data,
  });
}
//价格分布图表数据接口
export function getPriceRange(param) {
  return request({
    url: `${BASE_API}/api/sub/price_range`,
    method: "get",
    params: param,
  });
}
export function getPriceRangeUrl(data) {
  return request({
    url: `${BASE_API}/api/sub/price_range`,
    method: "post",
    data: data,
  });
}
//保存方案
export function saveProj(param) {
  return request({
    url: `${BASE_API}/api/plan`,
    method: "get",
    params: param,
  });
}
