<template>
  <div class="header">
    <div class="header-one">
      <div class="brand">
        <span style="margin: 0 4px; min-width: 110px">{{$t('itemHeader.filter')}} :</span>
        <el-tag v-if="plat"
                class="plat">
          {{ $t('baseInfo.plant') + '：' + platName }}
        </el-tag>
        <el-tooltip class="item"
                    effect="dark"
                    placement="bottom-start"
                    v-if="catName"
                    :content="catNameTip"
                    :disabled="!catNameTip">
          <el-tag class="catName tagBar">
            {{ $t('itemHeader.cat') +': ' + catName }}
          </el-tag>
        </el-tooltip>
        <el-tooltip class="item"
                    effect="dark"
                    placement="bottom-start"
                    v-if="itemName"
                    :content="itemNameTip"
                    :disabled="!itemNameTip">
          <el-tag size="mini"
                  class="itemName tagBar">
            {{ $t('baseInfo.item') + '：' + itemName }}
          </el-tag>
        </el-tooltip>

      </div>
      <div class="time-pick">
        <div style="margin: 0 8px"
             v-if="isShowCoupon && isShowUserCoupon">
          <el-select v-model="version"
                     :placeholder="$t('itemHeader.select_placeholder')"
                     size="mini"
                     style="width: 140px">
            <el-tooltip class="item"
                        effect="dark"
                        :content="item.date"
                        :disabled="!item.disabled"
                        placement="right-start"
                        v-for="item in couponList"
                        :key="item.value">
              <el-option :value="item.value"
                         :label="item.name"
                         :disabled="item.disabled"></el-option>
            </el-tooltip>
          </el-select>
        </div>
        <div style="margin: 0 8px"
             v-if="isShowJdVersion && loginInfo.username && loginInfo.username.endsWith('@moojing.com')">
          <el-select v-model="jdVersion"
                     @change="jdVersionChange"
                     placeholder="请选择"
                     size="mini"
                     style="width: 140px">
            <el-option v-for="item in jdVersionList"
                       :key="item.value"
                       :value="item.value"
                       :label="item.name"></el-option>
          </el-select>
        </div>
        <div style="margin: 0 8px"
             v-if="showDouyinVersion">
          <el-select v-model="douyinVersion"
                     @change="douyinVersionChange"
                     placeholder="请选择"
                     size="mini"
                     style="width: 140px">
            <el-option v-for="item in douyinVersionList"
                       :key="item.value"
                       :value="item.value"
                       :label="item.name"></el-option>
          </el-select>
        </div>
        <div class="timePick">
          <!-- <el-date-picker v-model="dateTime"
                          @change="dateChange"
                          :clearable="false"
                          unlink-panels
                          size="mini"
                          style="width:350px;"
                          value-format="yyyy-MM"
                          :picker-options="pickerOptions_start"
                          type="monthrange"
                          range-separator="至"
                          start-placeholder="开始月份"
                          end-placeholder="结束月份">
          </el-date-picker> -->
          <MonthPickerCpn v-model="dateTime"
                          @change="dateChange"
                          :max-date="$store.state.user.deadLineEndMonth"
                          :min-date="$store.state.user.deadLineStartMonth" />
          <!-- <el-select v-model="start"
                     size="mini"
                     :placeholder="$t('mainHead.date_holder')"
                     style="width:144px">
            <el-option v-for="(item,index) in timeList"
                       :key="index"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select>
          <span style="margin:0 4px">{{$t('mainHead.date_to')}}</span>
          <el-select v-model="end"
                     size="mini"
                     :placeholder="$t('mainHead.date_holder')"
                     style="width:144px">
            <el-option v-for="(item,index) in timeList"
                       :key="index"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select> -->
        </div>
        <div style="margin: 0 8px">
          <el-button size="mini"
                     type="primary"
                     @click="submitForm">确定</el-button>
        </div>
      </div>
    </div>

    <div class="header-two">
      <!-- <div style="width:100%"> -->
      <el-tabs v-model="editableTabsValue"
               type="card"
               @tab-click="handleTabClick">
        <el-tab-pane :key="item.name"
                     v-for="item in editableTabs"
                     :label="item.title"
                     :name="item.name"></el-tab-pane>
      </el-tabs>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { getItemInfo } from '@/api/baseInfo'
import { parseTime, build_ts, getObjFromUrl, getMoneySymbol } from '@/utils'
import { listenObj, timeRnage } from '@/mixins/mixins.js'
import {
  tbOptions,
  tmallOptions,
  jdOptions,
  amazonOptions,
  shopeeOptions,
  lazadaOptions,
  douyinOptions,
  tiktokOptions,
  ozonOptions,
} from './searchCpn/platOptions'
import CatTree from './searchCpn/CatTree.vue'
import MonthPickerCpn from '../components/monthPickerCpn/MonthPickerCpn.vue'
//import { getLastMonth } from '@/utils'

const jdList = JSON.parse(localStorage.getItem('jdVersionList'))
const douyinList = JSON.parse(localStorage.getItem('douyinVersionList'))
export default {
  mixins: [listenObj, timeRnage],
  components: {
    CatTree,
    MonthPickerCpn,
  },
  data() {
    return {
      isOversea: this.$store.state.user.isOversea,
      dateTime: [
        this.$store.state.user.startMonth,
        this.$store.state.user.endMonth,
      ],
      platTimeKey: '',
      platTimeName: this.$store.state.user.isOversea ? 'amazon_us' : 'taobao',
      ts: this.$store.state.user.ts,
      platList: [],
      platNameMap: {},
      couponList: [
        { name: this.$t('mainHeader.no_discount'), value: '' },
        {
          name: this.$t('mainHeader.discount_v1'),
          value: 'cp',
          date: '2020年1月起',
        },
        // {
        //   name: this.$t('mainHeader.full_discount'),
        //   value: 'min',
        //   date: '2020年1月起',
        // },
        {
          name: this.$t('mainHeader.full_discount_v2'),
          value: 'min_v2',
          date: '2020年11月起',
        },
      ],
      // 下拉框显示以用户配置为准 工单号: 88008
      couponListSku: [
        { name: this.$t('mainHeader.no_discount'), value: '' },
        {
          name: this.$t('mainHeader.discount_v1'),
          value: 'cp',
          date: '2020年1月起',
        },
        // {
        //   name: this.$t('mainHeader.full_discount'),
        //   value: 'min',
        //   date: '2020年1月起',
        // },
        {
          name: this.$t('mainHeader.full_discount_v2'),
          value: 'min_v2',
          date: '2020年11月起',
        },
      ],
      couponLists: [
        { name: this.$t('mainHeader.no_discount'), value: '' },
        {
          name: this.$t('mainHeader.discount_v1'),
          value: 'cp',
          date: '2020年1月起',
        },
        // {
        //   name: this.$t('mainHeader.full_discount'),
        //   value: 'min',
        //   date: '2020年1月起',
        // },
        {
          name: this.$t('mainHeader.full_discount_v2'),
          value: 'min_v2',
          date: '2020年11月起',
        },
      ],
      isShowCoupon: true,
      isShowUserCoupon: false,
      verMap: [],
      plat: this.$store.state.user.plants,
      cid: this.$store.state.user.cId,
      item_id: this.$store.state.user.itemId,
      start: this.$store.state.user.startMonth,
      end: this.$store.state.user.endMonth,
      version: localStorage.getItem('version')
        ? localStorage.getItem('version')
        : this.$store.state.user.version,
      sub_name: this.$store.state.user.subName,
      userVersionSelected: this.$store.state.user.userInfo
        ? this.$store.state.user.userInfo.version_selected || []
        : [],
      isMoojingUser: false,
      parseTime: parseTime,
      loading: false,
      loading_save: false,
      editableTabs: [
        {
          title: this.$t('mainHead.tabs_baseInfo'),
          name: 'BaseInfo',
          id: 'baseInfo',
        },
        {
          title: this.$t('mainHead.tabs_item_sku'),
          name: 'SkuList',
          id: 'skuList',
        },
        {
          title: this.$t('mainHead.tabs_item_sku_attr'),
          name: 'SkuAttrList',
          id: 'skuAttrList',
        },
      ],
      errorItem: { type: 'info', text: '' },
      loading: false,

      platName: '',
      catName: '',
      itemName: '',
      timeList: [],
      isSku_Grade: this.$store.state.user.isSkuGrade, //是否选择了sku单选框
      isSku_ActivedTab: false, //是否选择了属性tab页
      showDownloadBtn: 'true',
      catNameTip: '',
      itemNameTip: '',

      isShowJdVersion: false,
      jdVersion: JSON.parse(localStorage.getItem('jdVersion')),
      jdVersionList: jdList.map((item) => ({ name: item, value: item })),
      douyinVersion: JSON.parse(localStorage.getItem('douyinVersion')),
      douyinVersionList: douyinList.map((item) => ({
        name: item,
        value: item,
      })),
    }
  },
  computed: {
    showDouyinVersion() {
      if (
        this.loginInfo.username &&
        !this.loginInfo.username.endsWith('@moojing.com')
      ) {
        return false
      }
      if (this.plat.split(',').length > 1) {
        return false
      }
      if (this.plat.indexOf('douyin') > -1) {
        if (this.sub_name == 'custom_102') {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    languageMode() {
      return this.$store.state.user.languageMode
    },
    info_params() {
      let params = {
        cid: this.cid,
        item_id: this.item_id,
        plat: this.plat,
        end: this.$route.query.end ? this.$route.query.end : this.end,
        start: this.$route.query.start ? this.$route.query.start : this.start,
        sub_name: this.sub_name,
        site_lang: this.$i18n.locale,
        url_id: this.$route.query.url_id,
      }
      if (this.version) {
        params.version = this.version
      }
      return params
    },
    editableTabsValue: {
      get() {
        if (this.$store.state.user.itemActivedTab) {
          if (this.$store.state.user.itemActivedTab != 'BaseInfo') {
            this.isSku_ActivedTab = true
          }
          return this.$store.state.user.itemActivedTab
        } else {
          return 'BaseInfo'
        }
      },
      set(val) {
        this.$store.state.user.itemActivedTab = val
      },
    },
    isSku() {
      if (this.isSku_Grade || this.isSku_ActivedTab) {
        return true
      } else {
        return false
      }
    },
    loginInfo() {
      return this.$store.state.user.loginInfo
    },
  },
  mounted() {
    this.$router.onReady(() => {
      const showDownloadBtn = this.$route.query.showDownloadBtn
      if (showDownloadBtn) {
        if (showDownloadBtn == 'true') {
          this.$store.dispatch('user/changeShowDownloadBtn', true)
        } else if (showDownloadBtn == 'false') {
          this.$store.dispatch('user/changeShowDownloadBtn', false)
        }
      }
    })
  },
  created() {
    this.getItemInfoDataDebounce = _.debounce(this.getItemInfoData, 200)
    setTimeout(() => {
      if (this.plat.indexOf('jd') == 0) {
        this.isShowCoupon = false
        this.isShowJdVersion = true
        this.platNameMap = jdOptions
        this.editableTabs = [
          {
            title: this.$t('mainHead.tabs_baseInfo'),
            name: 'BaseInfo',
            id: 'baseInfo',
          },
        ]
      } else if (this.$store.state.user.isOversea) {
        this.isShowCoupon = false
        this.isShowJdVersion = false

        if (this.plat.indexOf('amazon') > -1) {
          this.platNameMap = amazonOptions
        }
        if (this.plat.indexOf('shopee') > -1) {
          this.platNameMap = shopeeOptions
        }
        if (this.plat.indexOf('lazada') > -1) {
          this.platNameMap = lazadaOptions
        }
        if (this.plat.indexOf('tiktok') > -1) {
          this.platNameMap = tiktokOptions
        }
        if (this.plat.indexOf('ozon') > -1) {
          this.platNameMap = ozonOptions
        }
        this.editableTabs = [
          {
            title: this.$t('mainHead.tabs_baseInfo'),
            name: 'BaseInfo',
            id: 'baseInfo',
          },
        ]
      } else if (this.plat.indexOf('douyin') == 0) {
        this.isShowCoupon = false
        this.isShowJdVersion = false
        this.platNameMap = douyinOptions
        const { top_id, sub_name } = getObjFromUrl(window.location.hash)
        if (sub_name !== 'custom_102' && this.douyinVersion !== 'v5') {
          this.editableTabs = [
            {
              title: this.$t('mainHead.tabs_baseInfo'),
              name: 'BaseInfo',
              id: 'baseInfo',
            },
          ]
        }
        const customSubNames = this.$store.state.user.customFilingCat
        const topIds = this.$store.state.user.filingCat
        if (
          customSubNames.some((name) => this.sub_name.includes(name)) ||
          topIds.includes(top_id)
        ) {
          if (this.plat !== 'cshop') {
            this.editableTabs.push({
              title: this.$t('mainHead.tabs_item_filing_info'),
              name: 'FilingInfo',
              id: 'filingInfo',
            })
          }
        }
      } else {
        const { top_id } = getObjFromUrl(window.location.hash)
        const customSubNames = this.$store.state.user.customFilingCat
        const topIds = this.$store.state.user.filingCat
        if (
          customSubNames.some((name) => this.sub_name.includes(name)) ||
          topIds.includes(top_id)
        ) {
          if (this.plat !== 'cshop') {
            this.editableTabs.push({
              title: this.$t('mainHead.tabs_item_filing_info'),
              name: 'FilingInfo',
              id: 'filingInfo',
            })
          }
        }
        this.platNameMap = Object.assign({}, tbOptions, tmallOptions)
      }
      let val = this.plat.split(',')
      if (val.length > 0) {
        let arr = val.map((item) => {
          if (
            item == 'cshop' ||
            item == 'global' ||
            item == 'tmall' ||
            item == 'tmallhk' ||
            item == 'chaoshi' ||
            item == 'tmallhk_gfzy' ||
            item == 'nochaoshi' ||
            item == 'notmallhk' ||
            item == 'tmqjd' ||
            item == 'tmzyzmd'
          ) {
            return 'taobao'
          } else if (
            item == 'jd' ||
            item == 'jd_only_self' ||
            item == 'jd_exclude_self' ||
            item == 'jd_hk' ||
            item == 'jd_home_self' ||
            item == 'jd_home_pop' ||
            item == 'jd_not_home_self' ||
            item == 'jd_not_home_pop'
          ) {
            return 'jd'
          }
        })
        this.platTimeName = _.uniq(arr).join()
      }
      this.platName = this.platNameMap[this.plat]
      this.getItemInfoDataDebounce()
    }, 100)
    // this.$store.dispatch('user/changeMoneySymbol', getMoneySymbol(this.plat))
  },
  methods: {
    dateChange(val) {
      //   if (val) {
      //     this.start = val[0]
      //     this.end = val[1]
      //   }
    },
    getTimeList() {
      if (this.sub_name) {
        this.platTimeKey = [this.platTimeName, this.sub_name].join('|')
      } else {
        this.platTimeKey = this.platTimeName
      }
      if (this.ts) {
        this.timeList = build_ts(
          this.ts[this.platTimeKey].end,
          this.ts[this.platTimeKey].start
        )
        this.$store.dispatch(
          'user/changeDeadLineStartMonth',
          this.ts[this.platTimeKey].start
        )
        this.$store.dispatch(
          'user/changeDeadLineEndMonth',
          this.ts[this.platTimeKey].end
        )
      }
    },
    getItemInfoData() {
      let param_val = getObjFromUrl(window.location.hash)
      let params = _.cloneDeep(this.info_params)
      if (this.jdVersion) {
        params.jd_version = this.jdVersion
      }
      if (this.douyinVersion) {
        params.douyin_version = this.douyinVersion
      }
      if (!this.$route.query.end) {
        return
      }
      if (param_val.commentUrlId) {
        params.comment_url_id = param_val.commentUrlId
      }
      getItemInfo(params)
        .then((res) => {
          if (res.status == 'ok') {
            this.$store.dispatch('user/changeSkuDetailItemInfo', res.result)
            this.catName = res.result.path_name
            this.itemName = res.result.title
            this.itemInfo = res.result
            if (this.isOversea) {
              this.catNameTip = ''
              this.itemNameTip = ''
              if (this.languageMode == 'system') {
                this.catName = res.result.path_name
                this.itemName = res.result.title
                this.catNameTip = res.result.path_name
                this.itemNameTip = res.result.title
              } else if (this.languageMode == 'source') {
                this.catName = res.result.path_name_source
                this.itemName = res.result.title_source
                this.catNameTip = res.result.path_name_source
                this.itemNameTip = res.result.title_source
              } else {
                this.catName = `${res.result.path_name}/${res.result.path_name_source}`
                this.itemName = `${res.result.title}/${res.result.title_source}`
              }
            }
          }
        })
        .catch((e) => {
          //   this.$message({
          //     message: e.backMsg,
          //     type: 'warning',
          //   })
        })
    },
    submitForm() {
      this.start = this.dateTime[0]
      this.end = this.dateTime[1]
      const query = this.$route.query
      query.start = this.start
      query.end = this.end
      this.$router.push({
        name: 'skuDetail',
        query,
      })
      this.$store.dispatch('user/changeStartMonth', this.start)
      this.$store.dispatch('user/changeEndMonth', this.end)
      this.$store.dispatch('user/changeVersion', this.version)
      localStorage.setItem('version', this.version)
      localStorage.setItem('jdVersion', JSON.stringify(this.jdVersion))
      this.$eventBus.$emit('jdVersionChange', this.jdVersion)
      localStorage.setItem('douyinVersion', JSON.stringify(this.douyinVersion))
      this.$eventBus.$emit('jdVersionChange', this.douyinVersion)
      this.timeCouponChange()
      this.getItemInfoDataDebounce()
    },
    handleRemove(key) {},
    handleTabClick(tab, event) {
      let item = this.editableTabs.find((item) => {
        return item.name == tab.name
      })
      this.$store.dispatch('user/changeItemActivedTab', tab.name)
      localStorage.setItem('itemActivedTab', tab.name)
    },
    buildVerList() {
      let self = this
      var verList = this.userVersionSelected
      if (this.isMoojingUser) {
        this.isShowUserCoupon = true
      } else {
        if (verList.length == 0) {
          this.isShowUserCoupon = false
        } else {
          let arr = []
          _.map(verList, function (v) {
            let item = _.find(self.couponLists, function (o) {
              return o.value == v
            })
            if (item) {
              arr.push(item)
            }
          })
          // 用户如果没设置'不减优惠卷权限',就自动增加进去
          // if (arr[0].name !== this.$t('mainHeader.no_discount')) {
          //   arr.unshift({ name: this.$t('mainHeader.no_discount'), value: '' })
          // }
          this.verMap = arr
          this.couponList = arr
          this.$store.dispatch('user/changeVersion', this.version)
          localStorage.setItem('version', this.version)
          this.isShowUserCoupon = true
          if (this.isSku) {
            if (this.verMap.length > 0) {
              let arr = []
              let couponListSku = this.couponListSku
              _.map(this.verMap, function (v) {
                let item = _.find(couponListSku, function (o) {
                  return o.value == v.value
                })
                if (item) {
                  arr.push(item)
                }
              })
              this.couponList = arr
            } else {
              this.couponList = null
              this.couponList = this.couponListSku
            }
            this.$forceUpdate()
          } else {
            if (this.verMap.length > 0) {
              let arr = []
              let couponLists = this.couponLists
              _.map(this.verMap, function (v) {
                let item = _.find(couponLists, function (o) {
                  return o.value == v.value
                })
                if (item) {
                  arr.push(item)
                }
              })
              this.couponList = arr
            } else {
              this.couponList = this.couponLists
            }
            this.$forceUpdate()
          }
          const storeVersion = localStorage.getItem('version')
          if (
            storeVersion &&
            arr.findIndex((item) => item.value == storeVersion) > -1
          ) {
            this.version = storeVersion
          } else {
            this.version = this.couponList[0].value
          }
          this.$store.dispatch('user/changeVersion', this.version)
          localStorage.setItem('version', this.version)
        }
      }
    },
    timeCouponChange() {
      if (new Date(this.dateTime[0]) < new Date('2020-01')) {
        let index = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'cp'
        })
        let index1 = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'min'
        })
        let index2 = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'min_v2'
        })
        if (this.couponList.length > 0) {
          if (index != -1) {
            this.$set(this.couponList[index], 'disabled', true)
          }
          if (index1 != -1) {
            this.$set(this.couponList[index1], 'disabled', true)
          }
          if (index2 != -1) {
            this.$set(this.couponList[index2], 'disabled', true)
          }
        }
      } else if (
        new Date('2020-01') <= new Date(this.dateTime[0]) &&
        new Date(this.dateTime[0]) < new Date('2020-11')
      ) {
        let index = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'cp'
        })
        let index1 = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'min'
        })
        let index2 = _.findIndex(this.couponList, function (tab) {
          return tab.value == 'min_v2'
        })
        if (index != -1) {
          this.$set(this.couponList[index], 'disabled', false)
        }
        if (index1 != -1) {
          this.$set(this.couponList[index1], 'disabled', false)
        }
        if (index2 != -1) {
          this.$set(this.couponList[index2], 'disabled', true)
        }
      } else {
        _(this.couponList).forEach(function (value) {
          value['disabled'] = false
        })
      }
      this.$forceUpdate()
    },
  },
  watch: {
    languageMode(newVal) {
      this.catNameTip = ''
      this.itemNameTip = ''
      if (newVal == 'system') {
        this.catName = this.itemInfo.path_name
        this.itemName = this.itemInfo.title
        this.catNameTip = this.itemInfo.path_name_source
        this.itemNameTip = this.itemInfo.title_source
      } else if (newVal == 'source') {
        this.catName = this.itemInfo.path_name_source
        this.itemName = this.itemInfo.title_source
        this.catNameTip = this.itemInfo.path_name
        this.itemNameTip = this.itemInfo.title
      } else {
        this.catName = `${this.itemInfo.path_name}/${this.itemInfo.path_name_source}`
        this.itemName = `${this.itemInfo.title}/${this.itemInfo.title_source}`
      }
    },
    sub_name: {
      handler(newVal, oldVal) {
        if (newVal == 'custom_102') {
          this.isShowCoupon = false
        } else {
          this.isShowCoupon = true
        }
      },
      immediate: true,
      deep: true,
    },
    '$store.state.user.itemActivedTab': {
      handler(newVal, oldVal) {
        if (newVal == 'BaseInfo') {
          this.isSku_ActivedTab = false
        } else {
          this.isSku_ActivedTab = true
        }
      },
    },
    isSku: {
      handler(newVal, oldVal) {
        if (newVal) {
          if (this.verMap.length > 0) {
            let arr = []
            let couponListSku = this.couponListSku
            _.map(this.verMap, function (v) {
              let item = _.find(couponListSku, function (o) {
                return o.value == v.value
              })
              if (item) {
                arr.push(item)
              }
            })
            this.couponList = arr
          } else {
            this.couponList = null
            this.couponList = this.couponListSku
          }
          this.$forceUpdate()
        } else {
          if (this.verMap.length > 0) {
            let arr = []
            let couponLists = this.couponLists
            _.map(this.verMap, function (v) {
              let item = _.find(couponLists, function (o) {
                return o.value == v.value
              })
              if (item) {
                arr.push(item)
              }
            })
            this.couponList = arr
          } else {
            this.couponList = this.couponLists
          }
          this.$forceUpdate()
        }
        if (
          this.couponList.findIndex((item) => item.value == this.version) == -1
        ) {
          this.version = this.couponList[0].value
          this.$store.dispatch('user/changeVersion', this.version)
          localStorage.setItem('version', this.version)
        }
        this.timeCouponChange()
      },
    },
    '$store.state.user.startMonth': {
      handler(newVal, oldVal) {
        this.start = newVal
        this.dateTime = [newVal, this.dateTime[1]]
      },
    },

    '$store.state.user.endMonth': {
      handler(newVal, oldVal) {
        this.end = newVal
        this.dateTime = [this.dateTime[0], newVal]
      },
    },
    '$store.state.user.loginInfo': {
      handler(newVal, oldVal) {
        this.isMoojingUser = newVal.username.endsWith('@moojing.com')
        this.loginInfo = newVal
        this.userVersionSelected = newVal.version_selected
        this.buildVerList()
        // this.$set(this, 'loginInfo', newVal)
      },
      immediate: true,
    },
    '$store.state.user.ts': {
      handler(newVal, oldVal) {
        this.ts = newVal
        this.getTimeList()
      },
      immediate: true,
      deep: true,
    },
    '$route.query': {
      handler(newVal, oldVal) {
        if (!oldVal.end && newVal.end) {
          this.end = newVal
          this.start = this.$route.query.start
        }
        this.getItemInfoDataDebounce()
      },
    },
  },
}
</script>

<style scoped lang="less">
.header {
  .header-one {
    .brand {
      flex: 1;
      flex-wrap: nowrap !important;
      overflow-x: auto;
    }
  }
  .myAtention {
    min-width: 100px;
  }
}
.catName {
  background: rgb(233, 174, 86);
  color: white;
}
.avgPrice {
  background: #427ff0;
}
.sold {
  background: #9338e9;
}
.sales {
  background: #74d150;
}
.title {
  background: #5bc0de;
}
.attrName {
  background: #7baaf1;
}
.itemName {
  background: #5bc0de;
}
.timePick {
  /deep/ .el-date-editor .el-range-separator {
    width: 7%;
  }
}
.filterIcon {
  height: 28px;
  width: 36px;
  text-align: center;
  line-height: 28px;
  border-radius: 2px;
  cursor: pointer;
  i {
    color: #333;
  }
}
.activeFilter {
  background: #3a63f333;
  i {
    color: #3a63f3;
  }
}
</style>
