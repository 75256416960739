import request from "@/utils/request";
// import { encryptByDES } from '@/utils/crypto'
import qs from "qs";
import axios from "axios";
const BASE_API = process.env.VUE_APP_BASE_API;
const LOGIN_API = process.env.VUE_APP_LOGIN_API;

export function getBrands(param) {
  return request({
    url: `${BASE_API}/api/brands`,
    method: "get",
    params: param,
  });
}
export function topDefaultAttr(data) {
  return request({
    url: `${BASE_API}/api/sub/query_top_attr`,
    method: "post",
    data,
  });
}
export function getMoreDefaultAttr(data) {
  return request({
    url: `${BASE_API}/api/sub/query_top_attr_values`,
    method: "post",
    data,
  });
}
export function topAttr(data) {
  return request({
    url: `${BASE_API}/api/sub/top_attr`,
    method: "post",
    data,
  });
}

export function titleTrans(data, method = "post") {
  return request({
    url: `${BASE_API}/api/translation`,
    method: method,
    [method === "get" ? "params" : "data"]: data,
  });
}

export function filingTopAttr(data) {
  return request({
    url: `${BASE_API}/api/sub/filing_top_attr`,
    method: "post",
    data,
  });
}
