<template>
  <div class="filing-table-cpn"
       v-if="data.length > 0 && showCpn">
    <span style="line-height: 40px;  position: relative; top: -50px; font-weight: 700;width: 80px">备案属性:</span>
    <table class="table"
           border="0"
           cellspacing="0"
           cellpadding="0"
           style="margin-bottom:0;">
      <tr v-for="item in attrvList"
          :key="item.id"
          style="line-height: 40px;">
        <td>
          <el-select v-model="item.name"
                     placeholder="请选择"
                     size="mini"
                     style="width: 188px; margin-right: 10px">
            <template v-for="sonItem,index in topAttrData">
              <el-option :value="sonItem.name_source || sonItem.name"
                         :label="sonItem.name_show || sonItem.name"
                         :disabled="item.isDisabledArr[index]"
                         @click.native="getAttrvListIndex()"
                         :key="sonItem.id">
                <el-tooltip class="item"
                            effect="dark"
                            :content="sonItem.name_tip"
                            :disabled="!sonItem.name_tip"
                            placement="left-start">
                  <div>{{sonItem.name_show || sonItem.name}}</div>
                </el-tooltip>
              </el-option>
            </template>
          </el-select>
        </td>
        <td>
          <el-input style="width: 188px; margin-right: 20px"
                    size="mini"
                    v-model="item.value"
                    placeholder="请输入属性筛选条件"></el-input>
        </td>
        <td>
          <i class="el-icon-circle-plus cp btColor mr10"
             style="margin-right: 10px"
             @click="addTableRow()"></i>
          <i class="el-icon-remove cp btColor"
             @click="deleTableRow(item.id)"></i>
        </td>
      </tr>
    </table>
  </div>
</template>


<script>
export default {
  name: 'FilingTable',
  data() {
    return {
      attrvList: [],
      topAttrData: [],
      showCpn: false,
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    defaultAttr: {
      type: Array,
      default: () => [],
    },
  },
  computed: {},
  methods: {
    getAttrvListIndex() {
      let valueArr = _.compact(_.map(this.attrvList, 'name'))
      let attrArr = _.map(this.topAttrData, 'name')
      let arr = []
      for (let i = 0; i < attrArr.length; i++) {
        arr.push(false)
        for (let j = 0; j < valueArr.length; j++) {
          if (attrArr[i] == valueArr[j]) {
            arr[i] = true
          }
        }
      }
      for (let i = 0; i < this.attrvList.length; i++) {
        this.attrvList[i].isDisabledArr = arr
      }
      this.$forceUpdate()
    },
    addTableRow() {
      this.getAttrvListIndex()
      this.attrvList.push({
        id: _.uniqueId(),
        name: '',
        type: '',
        value: '',
        isDisabledArr: this.attrvList[0].isDisabledArr,
      })
    },
    deleTableRow() {
      if (this.attrvList.length <= 1) {
        this.attrvList = [
          {
            id: _.uniqueId(),
            name: '',
            type: '',
            value: '',
            isDisabledArr: this.newDisabledArr,
          },
        ]
        this.$message({
          message: '至少有一条属性筛选条件！',
          type: 'warning',
        })
        return
      }
      let index = this.attrvList.findIndex((item) => item.id === id)
      this.attrvList.splice(index, 1)
      this.getAttrvListIndex()
    },
    init() {
      this.showCpn = true
      let arrs = []
      for (let i = 0; i < this.topAttrData.length; i++) {
        arrs.push(false)
      }
      this.newDisabledArr = arrs
      if (this.defaultAttr.length > 0) {
        this.attrvList = this.defaultAttr.map((item) => {
          let obj = {
            id: _.uniqueId(),
            name: item.name,
            value: item.value,
            isDisabledArr: arrs,
          }
          return obj
        })
      } else {
        this.attrvList = [
          {
            id: _.uniqueId(),
            value: '',
            type: '',
            name: '',
            isDisabledArr: arrs,
          },
        ]
      }
    },
    hide() {
      this.showCpn = false
      this.attrvList = []
    },
  },
  created() {
    if (this.data.length > 0 && this.defaultAttr.length > 0) {
      this.topAttrData = this.data
      this.init()
    }
  },
  mounted() {},
  watch: {
    data(newVal) {
      if (newVal.length > 0) {
        this.topAttrData = newVal
      }
    },
  },
}
</script>

<style lang="less" scoped>
.filing-table-cpn {
  display: flex;
  border-left: 1px solid #dcdfe6;
  .btColor {
    font-size: 20px;
  }
}
</style>
