<template>
  <div class="month-picker-panel">
    <div class="main-area">
      <div class="start-month">
        <MonthPicker :lang="lang"
                     ref="startPicker"
                     :default-month="startMonth"
                     :default-year="startYear"
                     :max-date="maxDate"
                     :min-date="minDate"
                     :no-default="canEmpty"
                     :clearable="canEmpty"
                     @clear="clearTime('start')"
                     @change="startChange"
                     @change-year="changeYear($event,'start')"></MonthPicker>
      </div>
      <div class="end-month">
        <MonthPicker :lang="lang"
                     ref="endPicker"
                     :default-month="endMonth"
                     :default-year="endYear"
                     :max-date="maxDate"
                     :min-date="minDate"
                     :no-default="canEmpty"
                     :clearable="canEmpty"
                     @clear="clearTime('end')"
                     @change="endChange"
                     @change-year="changeYear($event,'end')"></MonthPicker>
      </div>
    </div>
    <div class="btn-area">
      <el-button v-if="canEmpty"
                 size="mini"
                 @click="emptyTime"
                 style="position: absolute; left: 12px">{{$t('search.time_empty')}}</el-button>
      <el-button type="primary"
                 size="mini"
                 @click="changeTime">{{$t('search.confirm')}}</el-button>
      <el-button size="mini"
                 class="cancel-button"
                 @click="cancleChange">{{$t('search.cancel')}}</el-button>
    </div>
    <template v-if="isTrial">
      <Popper v-if="showPop"
              ref="popper"
              :popId="'MonthPickerCpnPop'"
              placement="bottom"
              target=".month-picker__month.inactive"
              :content="$t('search.month_picker_tooltip')"></Popper>
    </template>
  </div>
</template>


<script>
import { MonthPicker } from 'vue-month-picker'
import { DateTime } from 'luxon'
import Popper from '../Popper/Popper.vue'

const ZONE = 'Asia/Shanghai'
export default {
  name: 'PickerPanel',
  components: {
    MonthPicker,
    Popper,
  },
  props: {
    dateTime: {
      type: Array,
      default: () => [],
    },
    defaultMaxDate: {
      type: String,
      default: '',
    },
    defaultMinDate: {
      type: String,
      default: '',
    },
    canEmpty: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      startYear: new Date().getFullYear(),
      startMonth: 1,
      endYear: new Date().getFullYear(),
      endMonth: 1,
      startTime: '',
      endTime: '',
      lang: '',
      showPop: true,
    }
  },
  watch: {
    dateTime: {
      handler(newVal, oldVal) {
        this.startYear = Number(newVal[0].split('-')[0])
        this.endYear = Number(newVal[1].split('-')[0])
        this.startMonth = Number(newVal[0].split('-')[1])
        this.endMonth = Number(newVal[1].split('-')[1])
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    startChange(val) {
      if (isNaN(val.from)) {
        this.startYear = val.year
        this.startMonth = 1
      } else {
        this.startMonth = val.from.getMonth() + 1
        this.startYear = val.from.getFullYear()
      }
    },
    endChange(val) {
      if (isNaN(val.from)) {
        this.endYear = val.year
        this.endMonth = 1
      } else {
        this.endMonth = val.from.getMonth() + 1
        this.endYear = val.from.getFullYear()
      }
    },
    // 判断日期是否超过三年
    isDateIntervalGreaterThanThreeYears(date1, date2) {
      const yearDiff = date2.getFullYear() - date1.getFullYear()
      const monthDiff = date2.getMonth() - date1.getMonth()

      if (yearDiff > 3) {
        return true
      } else if (yearDiff === 3 && monthDiff >= 0) {
        return true
      }

      return false
    },
    changeTime() {
      // -----日期最大值为xxxx-01, 无法切换年份问题修改
      if (this.startYear !== this.$refs.startPicker.year) {
        this.startYear = this.$refs.startPicker.year
        this.startMonth = 1
      }
      if (this.endYear !== this.$refs.endPicker.year) {
        this.endYear = this.$refs.endPicker.year
        this.endMonth = 1
      }
      // -------
      if (this.startMonth && !isNaN(this.startMonth)) {
        this.startTime =
          this.startYear + '-' + this.prefixInteger(this.startMonth, 2)
      } else {
        this.startTime = ''
      }
      if (this.endMonth && !isNaN(this.endMonth)) {
        this.endTime = this.endYear + '-' + this.prefixInteger(this.endMonth, 2)
      } else {
        this.endTime = ''
      }
      if (
        (this.startTime, this.endTime) &&
        this.isDateIntervalGreaterThanThreeYears(
          new Date(this.startTime),
          new Date(this.endTime)
        )
      ) {
        this.$message.warning(this.$t('summary.selected_time_warning'))
        return
      }
      if (this.canEmpty) {
        if (!this.startMonth && this.endMonth) {
          this.endTime = this.$emit('on-changeTime', ['', this.endTime])
          return
        } else if (this.startMonth && !this.endMonth) {
          this.$emit('on-changeTime', [this.startTime, ''])
          return
        }
      }
      let defaultStartTs = DateTime.fromISO(this.defaultMinDate).setZone(
        ZONE
      ).ts
      let defaultEndTs = DateTime.fromISO(this.defaultMaxDate).setZone(ZONE).ts
      let startTs = DateTime.fromISO(this.startTime).setZone(ZONE).ts
      let endTs = DateTime.fromISO(this.endTime).setZone(ZONE).ts
      if (endTs < startTs) {
        this.$message.error(this.$t('search.time_error_message'))
        return
      }
      // 时间自动落在规定时间范围内
      if (this.endTime && endTs > defaultEndTs) {
        this.endTime = this.defaultMaxDate
      }
      if (this.startTime && startTs < defaultStartTs) {
        this.startTime = this.defaultMinDate
      }
      this.$emit('on-changeTime', [this.startTime, this.endTime])
    },
    clearTime(type) {
      if (type == 'start') {
        this.startMonth = NaN
      } else {
        this.endMonth = NaN
      }
    },
    cancleChange() {
      this.$emit('on-cancleChange')
    },
    prefixInteger(num, n) {
      return (Array(n).join(0) + num).slice(-n)
    },
    /*
      date: {
        type: String,
        sample: '2022-01'
      }
    */
    setDateZone(date) {
      return DateTime.fromISO(date).setZone(ZONE).toJSDate()
    },
    changeYear(year, type) {
      if (this.isTrial) {
        this.showPop = false
        this.$nextTick(() => {
          this.showPop = true
          this.$nextTick(() => {
            this.$refs['popper'].initPopper()
          })
        })
      }
    },
    emptyTime() {
      this.$emit('on-changeTime', ['', ''])
    },
  },
  computed: {
    maxDate() {
      if (this.defaultMaxDate) {
        return this.setDateZone(this.defaultMaxDate)
      } else {
        return this.setDateZone(DateTime.now().toISODate())
      }
    },
    minDate() {
      if (this.defaultMinDate) {
        // 时区统一为"上海"
        return this.setDateZone(this.defaultMinDate)
      } else {
        return this.setDateZone('2016-04')
      }
    },
    isTrial() {
      // trial_pri_flag : 试用用户 , ispay: 是否为付费用户
      return (
        this.$store.state.user.loginInfo.trial_pri_flag ||
        this.$store.state.user.loginInfo.ispay == 'nopay'
      )
    },
  },
  created() {
    if (localStorage.getItem('fenxiLanguage')) {
      this.lang = localStorage.getItem('fenxiLanguage')
    } else {
      this.lang = 'zh'
    }
  },
}
</script>

<style lang="less" >
.month-picker-panel {
  width: 600px;
  .main-area {
    display: flex;
    .month-picker__container {
      width: 300px;
    }
    .month-picker__month {
      margin: 5px 10px;
      border: 1px solid gray;
      border-radius: 4px;
    }
    .month-picker__month.selected {
      background-color: @fenxiPrimaryColor;
      box-shadow: inset 0 0 3px @fenxiPrimaryColor,
        0px 2px 5px rgb(85 176 242 / 20%);
      border-color: @fenxiPrimaryColor;
    }
    .month-picker__year button {
      height: 30px;
      line-height: 30px;
    }
  }
  .btn-area {
    height: 30px;
    margin-top: 10px;
    display: flex;
    flex-direction: row-reverse;
    position: relative;

    .el-button {
      margin-right: 10px;
    }
    .cancel-button:hover,
    .cancel-button:focus {
      color: @fenxiPrimaryColor;
      background-color: @fenxiPrimaryOpacityColor;
      border-color: @fenxiPrimaryColor;
    }
  }
}
.month-picker-panel .main-area .month-picker__year button {
  line-height: 26px;
}
</style>
