<template>
  <containElement>
  </containElement>
</template>

<script>
import containElement from '@/components/Container.vue'
import { getObjFromUrl } from '@/utils'
import { DateTime } from 'luxon'
import ErrorStackParser from 'error-stack-parser'
import { getBaseErrorObj } from '@/utils/index.js'
import { clientError } from '@/api/public'
export default {
  components: { containElement },
  /*computed: {
    searchParam() {
      return getObjFromUrl(window.location.href)
    },
  },*/
  methods: {
    chageFavicon() {
      if (this.$store.state.user.loginInfo.is_partner) {
        if (this.$store.state.user.loginInfo.partner_title) {
          document.title = this.$store.state.user.loginInfo.partner_title
        }
        let favicon = document.querySelector("link[rel~='icon']")
        if (this.$store.state.user.loginInfo.partner_ico_url) {
          favicon.href = this.$store.state.user.loginInfo.partner_ico_url
        } else {
          // favicon.href = '/favicon.ico'
        }
      }
    },
  },
  watch: {
    '$route.query': {
      handler(newVal, oldVal) {
        let param_val = getObjFromUrl(window.location.hash)
        if (param_val.url_id != undefined && param_val.url_id != '') {
          this.$store.dispatch(
            'user/changeUrlId',
            decodeURIComponent(param_val.url_id)
          )
        }
        if (param_val.c_id != undefined && param_val.c_id != '') {
          this.$store.dispatch(
            'user/changeCId',
            decodeURIComponent(param_val.c_id)
          )
        }
        if (param_val.item_id != undefined && param_val.item_id != '') {
          this.$store.dispatch(
            'user/changeItemId',
            decodeURIComponent(param_val.item_id)
          )
        }
        if (param_val.sub_name != undefined && param_val.sub_name != '') {
          this.$store.dispatch(
            'user/changeSubName',
            decodeURIComponent(param_val.sub_name)
          )
        }
        if (param_val.brand_id != undefined && param_val.brand_id != '') {
          this.$store.dispatch(
            'user/changeSelectedType',
            param_val.brand_id.split(',').map((item) => {
              return decodeURIComponent(item)
            })
          )
        }
        if (param_val.cat_id != undefined && param_val.cat_id != '') {
          this.$store.dispatch(
            'user/changeCatId',
            decodeURIComponent(param_val.cat_id)
          )
        }
        if (param_val.cat_name != undefined && param_val.cat_name != '') {
          this.$store.dispatch(
            'user/changeCatName',
            decodeURIComponent(param_val.cat_name)
          )
        }
        if (param_val.plat != undefined) {
          this.$store.dispatch(
            'user/changeplants',
            decodeURIComponent(param_val.plat)
            // param_val.plat.split(',').map((item) => {
            //   return decodeURIComponent(item)
            // })
          )
        }
        let { start, end } = param_val
        if (start && end) {
          const startTs = DateTime.fromISO(start).ts
          const endTs = DateTime.fromISO(end).ts
          if (endTs < startTs) {
            let temp = end
            end = start
            start = temp
            return this.$router.push({
              name: 'detail',
              query: {
                url_id: param_val.url_id,
                start: start,
                end: end,
              },
            })
          }
        }
        if (start != undefined) {
          this.$store.dispatch(
            'user/changeStartMonth',
            decodeURIComponent(start)
          )
        }
        if (end != undefined) {
          this.$store.dispatch('user/changeEndMonth', decodeURIComponent(end))
        }
        if (param_val.itemActivedTab != undefined) {
          this.$store.dispatch(
            'user/changeItemActivedTab',
            decodeURIComponent(param_val.itemActivedTab)
          )
        }
      },
      immediate: true,
    },
    '$store.state.user.loginInfo': {
      handler(newVal, oldVal) {
        this.chageFavicon()
      },
    },
  },
  created() {
    if (!localStorage.getItem('isClear')) {
      localStorage.clear()
      localStorage.setItem('isClear', 1)
    }
    this.chageFavicon()
    window.onerror = function (message, url, lineNumber, columnNumber, error) {
      console.error(
        'Error: ' + message + '\nURL: ' + url + '\nLine Number: ' + lineNumber
      )
      const parsedError = ErrorStackParser.parse(error)
      const baseObj = getBaseErrorObj()
      baseObj.error_msg = message
      baseObj.stackTrace = parsedError
      clientError(baseObj)
      return true // 阻止浏览器默认行为
    }
  },
}
</script>

<style>
</style>
