<template>
  <el-menu :default-openeds="defaultOpeneds"
           :default-active="defaultActive"
           @select="handelSelect"
           :class="theme">
    <div style="margin-top: -30px;margin-bottom: 10px">
      <BackCmi v-if="!$store.state.user.loginInfo.is_partner" />
    </div>
    <div class="usage-scenario"
         v-if="lang == 'zh' && !this.$store.state.user.loginInfo.is_partner">
      <a href="https://qmctsz2s8t.feishu.cn/docx/Vx2Udr2JBoDF1Gxb2Wcc3hAOnAd"
         target="_blank"
         class="scenario-content">
        <span class="scenario-text">头部品牌都在看的</span>
        <span class="scenario-text">使用场景大全</span>
      </a>
    </div>
    <!-- <el-submenu index="1">
      <template slot="title"><i class="el-icon-message"></i>导航一</template> -->
    <el-menu-item :index="item.index"
                  v-for="(item) in menueData"
                  @click="routeTo(item.routeName)"
                  :key="item.index"
                  :id="item.index">
      <div class="icon-div">
        <i :class="item.icon"
           class="menue-icon"></i>
      </div>
      <div class="span-div">
        <span slot="title"
              :title='item.label'>{{item.label}}</span>
      </div>
    </el-menu-item>
    <!-- <AsideTmallCpn v-if="!$store.state.user.loginInfo.is_partner" /> -->
    <div style="margin-left: 20px;margin-top: 0px;display: flex;align-items: center;color: #3b76f2;cursor: pointer;font-size: 14px;font-weight: bold "
         @click="dialogVisible = true"
         v-if="!$store.state.user.loginInfo.is_partner">
      <i class="iconfont icon-dengpao"
         style="font-size:20px;margin-right: 10px;"></i>
      {{$t('sideMenu.ai')}}
    </div>
    <el-dialog :modal-append-to-body="true"
               :append-to-body="true"
               :visible.sync="dialogVisible"
               :show-close="false"
               class="cost-reduce-dialog"
               width="1000px">
      <CostReduce />
    </el-dialog>
    <!-- </el-submenu> -->
  </el-menu>
</template>

<script>
import AsideTmallCpn from './AsideTmallCpn/AsideTmallCpn.vue'
import BackCmi from './BackCmi/index.vue'
import CostReduce from './CostReduce.vue'
import { jumpToBlank } from '@/utils'
export default {
  name: 'AsideMenue',
  components: {
    AsideTmallCpn,
    BackCmi,
    CostReduce,
  },
  data() {
    return {
      defaultOpeneds: ['1'], //['1', '3']
      //   defaultActive: '1-1',
      menueData: [
        {
          index: '1-1',
          label: this.$t('sideMenu.search'),
          routeName: 'search',
          icon: 'iconfont icon-sousuo1',
        },
        {
          index: '1-2',
          label: this.$t('sideMenu.subscription'),
          routeName: 'subscribe',
          icon: 'iconfont icon-star',
        },
        {
          index: '1-3',
          label: this.$t('sideMenu.follow'),
          routeName: 'myAttention',
          icon: 'iconfont icon-heart',
        },
        {
          index: '1-4',
          label: this.$t('sideMenu.concept'),
          routeName: 'rankList',
          icon: 'iconfont icon-liebiao',
        },
        {
          index: '1-5',
          label: this.$t('sideMenu.report'),
          routeName: 'tradeReport',
          icon: 'iconfont icon-baogao-copy',
        },
        {
          index: '1-6',
          label: this.$t('sideMenu.set'),
          routeName: 'setUp',
          icon: 'iconfont icon-shezhi',
        },
        {
          index: '1-7',
          label: this.$t('sideMenu.custom'),
          routeName: 'customList',
          icon: 'iconfont icon-manage',
        },
        {
          index: '1-8',
          label: this.$t('sideMenu.pivot'),
          routeName: 'pivotList',
          icon: 'iconfont icon-toushifenxi',
        },
      ],
      lang: localStorage.getItem('language'),
      theme: localStorage.getItem('theme'),
      dialogVisible: false,
      industryReportsUrl:
        'https://www.mktindex.com/static/industryreports/?f=industryreports',
    }
  },
  created() {
    this.chageMenue()
  },
  methods: {
    routeTo(routeName) {
      if (routeName == 'rankList') {
        jumpToBlank(this.chanceUrl)
        this.$nextTick(() => {
          document.getElementById('1-4').classList.remove('is-active')
        })
      } else if (routeName == 'tradeReport') {
        jumpToBlank(this.industryReportsUrl)
        this.$nextTick(() => {
          document.getElementById('1-5').classList.remove('is-active')
        })
      } else {
        this.$router.push({
          name: routeName,
        })
      }
    },
    handelSelect(index) {
      this.$store.dispatch('user/changeActivedMnenue', index)
    },
    chageMenue() {
      if (this.$store.state.user.loginInfo.partner == '希望信息') {
        this.menueData = this.menueData.slice(0, 2)
      } else if (this.$store.state.user.loginInfo.partner) {
        this.menueData = this.menueData.filter(
          (menu) => menu.routeName !== 'tradeReport'
        )
      }
    },
  },
  computed: {
    defaultActive() {
      // return this.$store.state.user.ActivedMnenue
      let index = '1-1'
      let routeName = this.$route.name,
        matchItem = null
      if (routeName) {
        // index = this.menueData.find((item) => {
        //   return item.routeName == this.$route.name
        // }).index
        matchItem = this.menueData.find((item) => {
          return item.routeName == this.$route.name
        })
        if (matchItem) {
          index = matchItem.index
        }
      }
      return index
    },
    chanceUrl() {
      if (window.location.host == 'fenxiplus.mktindex.com') {
        return 'https://chance.mktindex.com/dashboard#/concept'
      } else if (window.location.host == 'oversea.mktindex.com') {
        return 'https://chance.mktindex.com/dashboard#/amazon'
      } else if (window.location.host == 'fenxitest.mktindex.com') {
        return 'https://chancetest.mktindex.com/dashboard#/concept'
      } else if (window.location.host == 'overseatest.mktindex.com') {
        return 'https://chancetest.mktindex.com/dashboard#/amazon'
      } else {
        return 'https://chancetest.mktindex.com/#/concept'
      }
    }
  },
  watch: {
    '$store.state.user.loginInfo': {
      handler(newVal, oldVal) {
        this.chageMenue()
      },
    },
  },
}
</script>

<style scoped>
.el-icon-s-custom {
  width: 18px !important;
}
.icon-manage::before {
  font-size: 20px;
}
</style>

<style lang="less">
.orange.el-menu {
  background-color: #f4f5f7;
  border: none;
  padding-top: 60px;
  .el-menu-item {
    height: 60px;
    line-height: 60px;
    padding-left: 0 !important;
    display: flex;
    .menue-icon {
      margin-right: 12px;
      width: 24px;
      text-align: center;
      font-size: 18px;
      vertical-align: middle;
      color: inherit;
    }
  }

  .el-menu-item:hover {
    background-color: white;
    // border-left: 8px solid #3A63F3;
    box-shadow: 0px 2px 5px 0px rgba(159, 77, 0, 0.2);
    color: #ff7d00;
  }
  .el-menu-item::before {
    content: '';
    margin-right: 16px;
    box-sizing: content-box;
  }

  .el-menu-item.is-active {
    background-color: white;
    border-left: 5px solid #ff7d00;
    box-shadow: 0px 2px 5px 0px rgba(159, 77, 0, 0.2);
    color: #ff7d00;
  }

  .el-menu-item.is-active::before {
    content: '';
    margin-right: 16px;
    box-sizing: content-box;
  }
}
.default.el-menu {
  background-color: #f4f5f7;
  border: none;
  padding-top: 60px;
  .el-menu-item {
    height: 60px;
    line-height: 60px;
    padding-left: 0 !important;
    display: flex;
    // border-left: 8px solid rgba(0, 0, 0, 0);
    // margin-left: 8px;
    .menue-icon {
      margin-right: 12px;
      width: 24px;
      text-align: center;
      font-size: 18px;
      vertical-align: middle;
      color: inherit;
    }
  }

  .el-menu-item:hover {
    background-color: #3a63f33b;
    // border-left: 8px solid #3A63F3;
    box-shadow: 0px 2px 5px 0px rgba(159, 77, 0, 0.2);
    color: #3a63f3;
  }
  .el-menu-item::before {
    content: '';
    margin-right: 16px;
    box-sizing: content-box;
  }

  .el-menu-item.is-active {
    background-color: #3a63f33b;
    // border-left: 8px solid #3A63F3;
    box-shadow: 0px 2px 5px 0px rgba(159, 77, 0, 0.2);
    color: #3a63f3;
  }

  .el-menu-item.is-active::before {
    content: '';
    margin-right: 16px;
    box-sizing: content-box;
  }
}
.usage-scenario {
  .scenario-content:hover,
  .scenario-content:focus {
    text-decoration: unset;
  }
  .scenario-text {
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    margin-left: 28px;
  }
}
</style>
<style lang="less">
.cost-reduce-dialog {
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog {
    border-radius: 5px;
  }
  .el-dialog__body {
    padding: 0;
    border-radius: 5px;
  }
}
</style>
